import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';

import { Router } from '@angular/router';
import { SessionService } from 'app/services/session.service';
import { ErrorService } from 'app/services/error.service';
import { SessionsControllerService } from 'app/api';
import { FilterDocumentsService } from 'app/services/filter-documents.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent {

  @Input() selectedTab: any;


  firstTime = {};

  

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
    
    "type" : null,
    "title" : "PopupTabDocument",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  constructor(
    private cdr: ChangeDetectorRef,
    private filterDocumentsService: FilterDocumentsService,
              private sessionsControllerService: SessionsControllerService,
              private sessionService: SessionService,
              private router: Router,
              private errorService: ErrorService,
              ) {}

  getFilterDocuments() {
    return this.filterDocumentsService;
  }

  getSessionService() {
    return this.sessionService;
  }

  firstTimeInvoked = false;

  showedErrorPopup = false;

  showPopupIfNoSession() {


    if(!this.sessionService.sessionId) {

      this.showedErrorPopup = true;

      this.popupObject = {
        "type": 'error',
        "title": "Si è verificato un errore",
        "description": 'Sessione non trovata',
        "choices":[],
    
        "newPopup": !this.popupObject.newPopup,
        "lastPopupSemantic": "Errore dopo chiamata HTTP"
    
      }

    }
    
    /*

    */
  }

  // Metodo che evita un errore di Angular
  ngAfterViewChecked(){ 
    this.cdr.detectChanges();
   }

  calculatePresentDocuments(presentDocuments) {

    if(presentDocuments) {

      if(!this.firstTimeInvoked) {

        if(presentDocuments['Identity'] && presentDocuments['Identity'].length > 0) { // Se è presente almeno un documento Identity -> VISUALIZZA 1° TAB
          
          
          this.firstTimeInvoked = true;
          this.setSelectedTab('Identity');

          
          if(presentDocuments['Income'] && presentDocuments['Income'].length === 0) {
            $('#income').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Income"] = true;
          }
          if(presentDocuments['Other'] && presentDocuments['Other'].length === 0) {
            $('#other').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Other"] = true;
          }

          $('#' + 'income' + '-i').removeClass('sidebarClicked');
          $('#' + 'income' + '-p').removeClass('sidebarClicked');
          $('#' + 'other' + '-i').removeClass('sidebarClicked');
          $('#' + 'other' + '-p').removeClass('sidebarClicked');
          $('#' + 'identity' + '-i').addClass('sidebarClicked');
          $('#' + 'identity' + '-p').addClass('sidebarClicked');
          
        }
        
        else if(presentDocuments['Income'] && presentDocuments['Income'].length > 0) { // Se è presente almeno un documento Income -> VISUALIZZA 2° TAB
          
          this.firstTimeInvoked = true;
          this.setSelectedTab('Income');

          if(presentDocuments['Identity'] && presentDocuments['Identity'].length === 0) {
            $('#identity').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Identity"] = true;
          }
          if(presentDocuments['Other'] && presentDocuments['Other'].length === 0) {
            $('#other').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Other"] = true;
          }

          $('#' + 'income' + '-i').addClass('sidebarClicked');
          $('#' + 'income' + '-p').addClass('sidebarClicked');
          $('#' + 'other' + '-i').removeClass('sidebarClicked');
          $('#' + 'other' + '-p').removeClass('sidebarClicked');
          $('#' + 'identity' + '-i').removeClass('sidebarClicked');
          $('#' + 'identity' + '-p').removeClass('sidebarClicked');

        } 

        else if(presentDocuments['Other'] && presentDocuments['Other'].length > 0) { // Se è presente almeno un documento Other -> VISUALIZZA 3° TAB
          this.firstTimeInvoked = true;
          this.setSelectedTab('Other');

          if(presentDocuments['Income'] && presentDocuments['Income'].length === 0) {
            $('#income').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Income"] = true;
          }
          if(presentDocuments['Identity'] && presentDocuments['Identity'].length === 0) {
            $('#identity').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Identity"] = true;
          }

          $('#' + 'income' + '-i').removeClass('sidebarClicked');
          $('#' + 'income' + '-p').removeClass('sidebarClicked');
          $('#' + 'other' + '-i').addClass('sidebarClicked');
          $('#' + 'other' + '-p').addClass('sidebarClicked');
          $('#' + 'identity' + '-i').removeClass('sidebarClicked');
          $('#' + 'identity' + '-p').removeClass('sidebarClicked');

        } else {

          if(presentDocuments['Income'] && presentDocuments['Income'].length === 0) {
            $('#income').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Income"] = true;
          }
          if(presentDocuments['Identity'] && presentDocuments['Identity'].length === 0) {
            $('#identity').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Identity"] = true;
          }
          if(presentDocuments['Other'] && presentDocuments['Other'].length === 0) {
            $('#other').addClass('sidebar_not_allowed');
            this.filterDocumentsService.finishedDocuments["Other"] = true;
          }

        }
      
      }

    }

  }



  // Metodo setter per selectedTab
  public setSelectedTab(tabName: string): void {
    
    this.selectedTab = tabName;
    this.sessionService.tabClicked = tabName;


    if(tabName === 'Identity') {
      this.firstTime['Identity'] = true;
    } else if(tabName === 'Income') {
      this.firstTime['Income'] = true;
    } else if(tabName === 'Other') {
      this.firstTime['Other'] = true;
    } 
  }



  // Termina sessione
    /* L'invocazione di questo metodo fa terminare la sessione in corso*/
    public async terminateSession() {

      this.sessionService.clickedTerminateSession = true;

      let responseSessionsControllerService = this.sessionsControllerService.setSessionStateCompleted(this.sessionService.sessionId);
      responseSessionsControllerService.subscribe(
        res => {
 
          let description = 'Sono stati correttamente acquisiti i seguenti documenti: <br /> <br />' + 

          this.completedDocumentsToStringList() +

          'Premi <b>Ok</b> per completare la sessione.';
    
          this.popupObject = {
            "type": 'info',
            "title": "Caricamento completato",
            "description": description,
            "choices":['Ok'],
        
            "newPopup": !this.popupObject.newPopup,
            "lastPopupSemantic": "Caricamento completato"
        
          }
  
        }, (error) => {

          this.popupObject = {
            "type": 'error',
            "title": "Si è verificato un errore",
            "description": this.errorService.getErrorDescription(error.status),
            "choices":['Ok'],
        
            "newPopup": !this.popupObject.newPopup,
            "lastPopupSemantic": "Errore dopo chiamata HTTP"
        
          }
    
        }
      );
      
    }

  public completedDocumentsDescriptions = []; // Array contenente le descrizioni (in italiano) dei documenti già caricati per la sessione corrente

  addItemToTheSession(document_description) {
    this.completedDocumentsDescriptions.push(document_description);
  }

  removeItemToTheSession(document_description) {
    const index = this.completedDocumentsDescriptions.indexOf(document_description);
    if (index > -1) {
      this.completedDocumentsDescriptions.splice(index, 1);
    }
  }

  // Scelta del popup
  selectedChoicePopup(choice: string) {

    if(this.popupObject.lastPopupSemantic === "Caricamento completato" && choice === 'Ok') {
      this.router.navigateByUrl("/resultPage"); // Vai alla pagina dei risultati
    }

  }



  public deleteStatusTerminateSession(documentTypeId) {
    delete this.sessionService.cardsForTerminateSession[documentTypeId];
    this.sessionService.terminateSessionPossible = this.isPossibleToFinish();
  }
  

  public changeStatusTerminateSession(isPossibleTerminate) {

    this.sessionService.cardsForTerminateSession[isPossibleTerminate.responsibleCard] = isPossibleTerminate.possibleToFinish; // Aggiorno il dizionario

    this.sessionService.terminateSessionPossible = this.isPossibleToFinish();
  }

  lastResumedCard;

  isPossibleToFinish() {

    if(Object.keys(this.sessionService.cardsForTerminateSession).length === 0) {
      return false;
    } 
    
    for(const key in this.sessionService.cardsForTerminateSession) {
      if(this.sessionService.cardsForTerminateSession[key] === false) {
        return false;
      }
    }

    if(Object.keys(this.sessionService.cardsForTerminateSession).length === 1) {
      if(this.sessionService.cardsForTerminateSession[Object.keys(this.sessionService.cardsForTerminateSession)[0]] === false) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

    /*
    if(Object.keys(this.sessionService.cardsForTerminateSession).length === 1 && this.lastResumedCard === this.sessionService.cardsForTerminateSession[Object.keys(this.sessionService.cardsForTerminateSession)[0]]) {
      return false;
    } else if(Object.keys(this.sessionService.cardsForTerminateSession).length === 1) {
      this.lastResumedCard = this.sessionService.cardsForTerminateSession[Object.keys(this.sessionService.cardsForTerminateSession)[0]];
      return false;
    } else {
      return true;
    }
    */

  }

  // Restituisce una lista non ordinata in HTML 
  private completedDocumentsToStringList() {
    let stringUl = "";
    for(let document_description of this.completedDocumentsDescriptions) {
      stringUl += '<div><strong>' + document_description + '</strong></div><br>';
    }
    return stringUl;
  }


}