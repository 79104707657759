import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public tabClicked = "";

  public clickedTerminateSession = false; // A true se è stato cliccato dall'utente il pulsante per terminare la sessione

  public blockSession = false;

  public cardsForTerminateSession = {}; // Dizionario che associa al nome di un tipo di documento (cioè una card) il fatto che la sessione può terminare o no

  public terminateSessionPossible = false; // Booleano settato a true quando è possibile terminare la sessione;

  public sessionId: string; // SessionId della sessione in corso

  constructor(private activatedRoute: ActivatedRoute) {
    this.obtainSessionId();
  }

  public getSessionId() {
    return this.sessionId;
  }

  public async obtainSessionId(): Promise<void> {
        
    let responseActivatedRoute = this.activatedRoute.queryParams;
    responseActivatedRoute.subscribe(
      params => {

        if(Object.keys(params).length != 0) { // Se ci sono dei query params
      
          console.log("ci sono query")
  
          if ('sessionId' in params) { // Se nei parametri c'è il sessionId
          
            this.sessionId = params.sessionId;
      
          } else { // Se non c'è l'OTP, NON siamo nell'happy path: dovremo gestire la situazione 
      
            console.log("ERRORE: query param sessionId non presente 1 ");
            
          }
        } //else {
          //console.log("ERRORE: query param sessionId non presente 2");
        //}

      }, (error) => {

        console.log("error:", error)

      }
    )
    
  
  }


}
