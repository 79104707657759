import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CrossCheckControllerService, DocumentsControllerService, ElaborationsControllerService } from 'app/api';
import { ErrorService } from 'app/services/error.service';
import { FilterDocumentsService } from 'app/services/filter-documents.service';
import { SessionService } from 'app/services/session.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  styleUrls: ['./result-page.component.css']
})
export class ResultPageComponent implements OnInit {

 

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
        
    "type" : null,
    "title" : "PopupUploadCard",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  public allDocumentsOfSession = []; // Sono tutti i documenti caricati per la sessione che si vuole mostrare.

  public elaborations = {}; // Dato un docId, inserisce qui le sue elaborazioni


  docsCrossCheck;
  globalCrossCheck;

  constructor(
    private documentsControllerService: DocumentsControllerService,
    private elaborationsControllerService: ElaborationsControllerService,
    private filterDocumentsService: FilterDocumentsService,
    private crossCheckControllerService: CrossCheckControllerService,
    private dom: DomSanitizer,
    private sessionService: SessionService,
    private errorService: ErrorService,
    private router: Router
  ) {
    
  }

  getFilterDocumentsService() {
    return this.filterDocumentsService;
  }

  // Dato un docId, restituisce il corrispondente documento all'interno dell'array allDocumentsOfSession
  private getDocumentGivenDocId(docId) {

    for(let document of this.allDocumentsOfSession) {
      if(document.docId === docId) {
        return document;
      }
    }
    return null;

  }

  // Dato un elabId, restituisce il corrispondente sotto-oggetto all'interno dell'array allDocumentsOfSession relativo a quella elaborazione
  private getSpecificElaborationGivenElabId(elabId) {

    for(let document of this.allDocumentsOfSession) {

      if(document.elaborations) {
        for(let elaboration of document.elaborations) {
          if(elaboration.elabId === elabId) {
            return elaboration;
          }
        }
      }


    }
    return null;

  }


  // Metodo di utilità che dato un ArrayBuffer restituisce l'equivalente stringa in base64
  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  public visualizeBase64(type, fileString) {

      return "data:"+type+";base64,"+fileString;
    
  }

  async ngOnInit() {

    window.onbeforeunload = function() { return "Your work will be lost."; };


    // 1° chiamata: getDocuments -> Restituisce tutti i documenti caricati per la sessione attuale
    let getDocumentsResponse = this.documentsControllerService.getDocuments(this.sessionService.sessionId);
    getDocumentsResponse.subscribe(
      async res => {
        
        this.allDocumentsOfSession = res;

        for(let document of this.allDocumentsOfSession) {
          // 2° chiamata: getElaborations -> Restituisce tutti i documenti caricati per la sessione attuale

          let responseElaborations = this.elaborationsControllerService.getElaborations(this.sessionService.sessionId, document['docId']);
          responseElaborations.subscribe(
            async resResponseElaborations => {

              this.getDocumentGivenDocId(document['docId'])['elaborations'] = resResponseElaborations;


              // Fai la localCrossCheck del documento
              let responseLocalCrossCheck = this.crossCheckControllerService.getDocumentCrossCheck(this.sessionService.sessionId, document['docId'])
              responseLocalCrossCheck.subscribe(
                resResponseLocalCrossCheck => {
          
                  this.getDocumentGivenDocId(document['docId'])['localCrossCheckData'] = resResponseLocalCrossCheck;
                  
                }, (error) => {

                  if(error.status !== 404) {
                    this.popupObject = {
                      "type": 'error',
                      "title": "Si è verificato un errore",
                      "description": this.errorService.getErrorDescription(error.status),
                      "choices":['Ok'],
                  
                      "newPopup": !this.popupObject.newPopup,
                      "lastPopupSemantic": "Errore dopo chiamata HTTP"
                    }
                  }
                }
              )




              for(let elaboration of this.getDocumentGivenDocId(document['docId'])['elaborations']) { // Per ogni elaborazione

                let documentTypeObtained = this.filterDocumentsService.getDocumentTypeObject(document.documentType);

                let submissionTypes = [];
                for(let type of documentTypeObtained['submissionTypes']) {
                  submissionTypes.push(type.submissionTypeId)
                }
                submissionTypes = [...new Set(submissionTypes)]; // Elimina tutte le stringhe duplicate

                let typeOfDocument = '';

                
                if(submissionTypes.includes('IMAGE')) { //  && submissionTypes.length === 1

                  typeOfDocument = "image/jpg";

                } else {

                  typeOfDocument = "application/pdf";

                }
                
                let currentDocumentElaboration = this.getSpecificElaborationGivenElabId(elaboration.elabId);

                currentDocumentElaboration['type'] = typeOfDocument;

                let responsePreview = this.elaborationsControllerService.getContent(this.sessionService.sessionId, document.docId, elaboration.elabId, typeOfDocument);
                responsePreview.subscribe(
                  async resResponsePreview => {
                    
                    currentDocumentElaboration['preview'] = this.visualizeBase64(typeOfDocument,this.arrayBufferToBase64(resResponsePreview));
                    currentDocumentElaboration['preview_pdf'] = this.dataURLtoFile(currentDocumentElaboration['preview']);

                    


                  }, (error) => {
                    this.popupObject = {
                      "type": 'error',
                      "title": "Si è verificato un errore",
                      "description": this.errorService.getErrorDescription(error.status),
                      "choices":['Ok'],
                  
                      "newPopup": !this.popupObject.newPopup,
                      "lastPopupSemantic": "Errore dopo chiamata HTTP"
                  
                    }
                  }
                )

              }

            }
          );
          
        }

        // Fai la globalCrossCheck
        let responseGlobalCrossCheck = this.crossCheckControllerService.getGlobalCrossCheck(this.sessionService.sessionId);
        responseGlobalCrossCheck.subscribe(
          res => {
            this.docsCrossCheck = res["docsCrossCheck"];
            this.globalCrossCheck = res["globalCrossCheck"]; // se null, no tabella
            console.log("this.globalCrossCheck: ", this.globalCrossCheck)
          }, (error) => {
            if(error.status !== 404) { 
              this.popupObject = {
                "type": 'error',
                "title": "Si è verificato un errore",
                "description": this.errorService.getErrorDescription(error.status),
                "choices":['Ok'],
            
                "newPopup": !this.popupObject.newPopup,
                "lastPopupSemantic": "Errore dopo chiamata HTTP"
            
              }
            }
          }
        )

        


      }, (error) => {
        this.popupObject = {
          "type": 'error',
          "title": "Si è verificato un errore",
          "description": this.errorService.getErrorDescription(error.status),
          "choices":['Ok'],
      
          "newPopup": !this.popupObject.newPopup,
          "lastPopupSemantic": "Errore dopo chiamata HTTP"
      
        }
      }
    );




  }

  private dataURLtoFile(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], 'filename', {type:mime});
  }

  truncDecimal(number) {
    return Math.round(number * 100) / 100

  }

  createNewSession() {
    this.sessionService.clickedTerminateSession = false;
    this.router.navigateByUrl("/"); // Vai alla pagina dei risultati
    
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    //console.log("vado indietro")
  }

}
