import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CreateSessionService } from 'app/services/create-session.service';
import { ObtainJWTService } from 'app/services/obtain-jwt.service';

@Component({
  selector: 'app-redirect-after-session-id',
  templateUrl: './redirect-after-session-id.component.html',
  styleUrls: ['./redirect-after-session-id.component.css']
})
export class RedirectAfterSessionIdComponent implements OnInit {

  sessionIdObtained = '';

  constructor(private createSessionService: CreateSessionService,
    private obtainJWTService: ObtainJWTService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //location.reload();
    this.activatedRoute.queryParams.subscribe(params => {
       let isDesktop = params['isDesktop'];
     let responseCreateSession = this.createSessionService.createSession();
    responseCreateSession.subscribe(
      res => {
        this.sessionIdObtained = res['sessionId'];

        let desktopUrl =  res['desktopUrl'];

        console.log("Il sessionId è: ", this.sessionIdObtained)

        console.log("Il desktopUrl è: ", desktopUrl)

        let otp = this.getQueryStringByParameter(desktopUrl, 'otp');

        console.log("L'otp è: ", otp);

        if (isDesktop==='false'){
          // Redirect
          this.router.navigate(['/qrCode'], { queryParams: {sessionId: this.sessionIdObtained, otp} })
        } else {
          let responseObtainJWT = this.obtainJWTService.obtainJWT(this.sessionIdObtained, otp);
        responseObtainJWT.subscribe(
          res => {
          }, (error) => {

            // Redirect
            this.router.navigate(['/DocumentsUpload'], { queryParams: {sessionId: this.sessionIdObtained} })

          });
        }

      }, (error) => {
        console.log("ERRORE 1: ", error);
      }
    );
    });


    
  }   

  
  private getQueryStringByParameter(url, parameterName) {
    parameterName = parameterName.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + parameterName + "=([^&#]*)"),
    results = regex.exec(url);
    return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

}
