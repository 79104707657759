import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as $ from 'jquery';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit, OnChanges {

  @Input() identifier: string; // Identificativo univoco per il popup

  @Input() title: string;

  @Input() choices: string[]; // La lunghezza di questo array indica la quantità di opzioni cliccabili (da 1 a 3)

  @Input() icon: string; // Icona del popup




  @Output() answerOfPopup = new EventEmitter(); // Propaga la risposta dell'utente a chi ha invocato il popup

  componentCreated;

  hidePopup: boolean = true; // A false quando il popup si vede, a true quando si chiude

  @Input() newPopup: boolean; // Quando questo booleano cambia rispetto al precedente, significa che deve essere creato un nuovo popup (che può avere le stesse proprietà del precedente)

  @Input() type: string; // Tipologia di popup (error, info, warning)


  @Input() description: string; // Descrizione del popup: indica all'utente che cosa deve fare





  constructor() { }

  ngOnChanges() {

    if(!this.componentCreated) {
      this.componentCreated = true;
    } else {
      this.hidePopup = false;

      console.log("this.identifier: ", this.identifier)

      $('#' + this.identifier + '-buttonPopup').click();
          

    }

    
  }

  ngOnInit(): void {


    
  }


  propagateAnswer(choice) {
    console.log("Risposta propagata: ", choice)
    this.answerOfPopup.emit(choice);
  }

}
