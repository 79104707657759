import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SessionService } from 'app/services/session.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Output() emitSelectedTab = new EventEmitter(); // Emette verso il parent la stringa della tab cliccata

  constructor(private sessionService: SessionService) { }

  ngOnInit(): void {
  }


  showSectionIdentita() {

    if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {
      if(!$("#buttonIdentita").hasClass( "tab_not_allowed" )) {

        $('#buttonReddituali').removeClass('active');
        $('#buttonAltro').removeClass('active');
  
        $('#buttonIdentita').addClass('active');
  
        this.emitSelectedTab.emit("Identity");
  
      }
    }

  }

  showSectionReddituali() {

    if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {

      if(!$("#buttonReddituali").hasClass( "tab_not_allowed" )) {
        $('#buttonIdentita').removeClass('active');
        $('#buttonAltro').removeClass('active');
    
        $('#buttonReddituali').addClass('active');
    
        this.emitSelectedTab.emit("Income");
      }
    }
  }

  showSectionAltro() {

    if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {
      if(!$("#buttonAltro").hasClass( "tab_not_allowed" )) {

        $('#buttonReddituali').removeClass('active');
        $('#buttonIdentita').removeClass('active');
  
        $('#buttonAltro').addClass('active');
  
        this.emitSelectedTab.emit("Other");
      }
    }
  }

}
