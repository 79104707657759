import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import * as pdfjs from 'pdfjs-dist/build/pdf.js';
import * as pdfjsWorker  from 'pdfjs-dist/build/pdf.worker.entry.js';


import imageCompression from 'browser-image-compression';

import packageInfo from '../../../../config.json';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionService } from 'app/services/session.service';
import { FilterDocumentsService } from 'app/services/filter-documents.service';
import { DocumentModel, DocumentsControllerService, ElaborationsControllerService, ServicesControllerService } from 'app/api';
import { ErrorService } from 'app/services/error.service';


@Component({
  selector: 'app-upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.css']
})
export class UploadCardComponent implements OnInit {

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
        
    "type" : null,
    "title" : "PopupUploadCard",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  // Servono per lo spinner
  element;
  loadingSpinner;  

  @Input() identifier: string;

  @Input() typeCard: string;

  @Output() deleteDocumentForFinish = new EventEmitter();

  @Output() createNewCardWithoutDocument = new EventEmitter(); // Evento invocato verso il parent quando si decide di sottomettere un nuovo documento.

  @Output() terminateSessionIsPossible = new EventEmitter(); // Evento invocato (porta con sé un booleano) che specifica al parent che potrebbe essere il momento di terminare la sessione

  @Output() terminateSession = new EventEmitter(); // Evento che permette di far terminare una sessione (Scatenato grazie al popup con tre scelte)

  @Output() addItemToSession = new EventEmitter(); // Evento che permette di aggiungere il nome di un documento (di cui tutte le 'part' sono caricate) alla sessione

  @Output() removeItemToSession = new EventEmitter(); // Evento che permette di rimuovere il nome di un documento dalla sessione a seguito della cancellazione di una delle 'part'

  public loadedPage: boolean = false; // Messo a false se è la prima volta che la pagina è caricata, true altrimenti

  public loading: boolean; // A true per fare apparire il piccolo spinner di caricamento

  public selectedDocument; // è l'oggetto selezionato attualmente nel menu a tendina

  public submissionTypes = []; // Array che indica i possibili modi in cui può essere acquisito il documento

  private allowedImagesFormats = ['image/jpeg', 'image/jpg', 'image/png']; // Formati consentiti per quanto riguarda le immagini

  private allowedIncomeFormats = ['application/pdf']; // Formati consentiti per quanto riguarda i PDF

  public showTooltip: boolean = false; // A true se viene visualizzato il tooltip sul pulsante per i PDF

  public alreadyExcluded: boolean = false;  // A true quando è stato emesso l'evento per creare una nuova cards oltre a questa. In questo modo si evita di cancellare il documento attualmente selezionato (visibile nel menu a tendina) con il side-effect

  public documentsAlreadyUploadRetrived = false;

  public onePartIsError = false; // Messa a true se dopo il caricamento di una qualche 'part' viene segnalato un error.

  recoverPhase = false; // Messa a false quando non siamo più nella fase di F5

  public showFinalPopup: boolean = false; // Viene messo a true ogni volta che viene caricato con successo un documento. Serve per visualizzare il popup finale (3 scelte) se entrambi FRONTE e RETRO sono stati caricati

  /* Dizionari. Associano ad una 'part'  */
  public imageFiles = {}; // Metadati per le immagini
  public urls = {}; // Serve per visualizzare nel browser l'immagine
  public valueProgressBars = {}; // Dizionario di valori compresi tra 0 e 100 che indica il completamento di una barra per il caricamento di una immagine
  public uploadedPhotos = {}; // Dizionario di booleani che sono messi a true dopo l'avvenuta elaborazione della foto che è stata caricata
  public statusAfterUploads = {}; // Dizionario i cui elementi possono assumere i valori SUCCESS, WARNING o ERROR per una immagine
  public cancella = {}; // Dizionario di booleani che messi a true dicono se è stato premuto il pulsante per dare il via alla cancellazione di una immagine
  public docIds = {};  // Dizionario di docId restituito dopo l'upload di una immagine
  public labelsPredicted = {}; // Dizionario che data una 'part' associa le label predette per quella part.
  public elabList = {}; // Dizionario che data una 'part' associa una lista di elaborazioni (tipicamente 3) per quella part



  constructor(
    private sessionService: SessionService,
    private cdr: ChangeDetectorRef,
    private filterDocuments : FilterDocumentsService,
    private dom: DomSanitizer,
    private servicesControllerService: ServicesControllerService,
    private documentsControllerService: DocumentsControllerService,
    private elaborationsControllerService: ElaborationsControllerService,
    private errorService: ErrorService) {

      this.element = document.getElementById('container');
      this.loadingSpinner = document.getElementById('loading');





      
  }


  finishUploadAfterDoCheck = false;

  
  ngDoCheck() {
    

    if(this.sessionService.tabClicked === this.typeCard && this.selectedDocument && this.finishUploadAfterDoCheck === false && !this.alreadyExcluded) {

      if(this.allPartAreUploaded() && this.recoverPhase == false) { // TODO: Attenzione ad F5: this.recoverPhase == false || this.documentsAlreadyUpdated.length == 0
        console.log("allPartAreUploaded!");
        let descriptionString; 

        if(this.excludeCategoriesOfDocumentNotShowed(this.filterDocuments.localDocuments[this.typeCard]).length === 1) {

          if(this.thisIsTheLastDocument()) { // Se è l'ultimissimo degli ultimissimi, nessun cambia tab

            let descriptionString; 
    
            this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' <b>Aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' Premi su <b>Aggiorna Documento</b> per aggiornare il documento appena caricato, o su <b>Cambia Tab</b> caricare altre tipologie di documenti.'
    
            console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
            console.log("this.typeCard: ", this.typeCard)
            
            if(this.sessionService.tabClicked === this.typeCard) {
              this.popupObject = {
                "type": 'info',
                "title": "Documento inserito con successo",
                "description": descriptionString,
                "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Termina sessione'] : ['Aggiorna documento'],
        
                "newPopup": !this.popupObject.newPopup,
                "lastPopupSemantic": "Documento caricato"
              };
            }
    
    
          } else {
                      
            this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' <b>Aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' Premi su <b>Aggiorna Documento</b> per aggiornare il documento appena caricato, o su <b>Cambia Tab</b> caricare altre tipologie di documenti.'

            console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
            console.log("this.typeCard: ", this.typeCard)
            if(this.sessionService.tabClicked === this.typeCard) {
              this.popupObject = {
                "type": 'info',
                "title": "Documento inserito con successo",
                "description": descriptionString,
                "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Cambia tab', 'Termina sessione'] : ['Aggiorna documento', 'Cambia tab'],
        
                "newPopup": !this.popupObject.newPopup,
                "lastPopupSemantic": "Documento caricato"
              };
            }

          }

          
          
        } else {
        
          this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + '<br />' + '<b>Carica</b> un nuovo documento, <b>aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + '<br />' + '<b>Carica</b> un nuovo documento oppure <b>aggiorna</b> il precedente.'

          console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
          console.log("this.typeCard: ", this.typeCard)
          if(this.sessionService.tabClicked === this.typeCard) {
            this.popupObject = {
              "type": 'info',
              "title": "Documento inserito con successo",
              "description": descriptionString,
              "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Nuovo documento', 'Termina sessione'] : ['Aggiorna documento', 'Nuovo documento'],
      
              "newPopup": !this.popupObject.newPopup,
              "lastPopupSemantic": "Documento caricato"
            };
          }
          

        }

      }

      this.finishUploadAfterDoCheck = true; 
      
    } /*else if(this.sessionService.tabClicked !== this.typeCard && this.selectedDocument) {
      this.finishUploadAfterDoCheck = false;
    } */

    
  }
  


  // Questo metodo funziona con F5
  ngOnInit(): void {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    
  }

  getSessionService() {
    return this.sessionService;
  }


  public getData() {
    const error = new HttpErrorResponse({ status: 400 });
    return of(error) as any;
  }

  partsFilled = 0;



  // Metodo di utilità che dato un ArrayBuffer restituisce l'equivalente stringa in base64
  private arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  F5Status = false;

  public async F5(documentsAlreadyUploaded) {

    // Visualizza lo spinner

    
    

    console.log("f5: ", documentsAlreadyUploaded)


    // Abbiamo sia i tipi di documenti già caricati, sia quanti sono questi dati
    if(documentsAlreadyUploaded) {


      // Prima cosa: provo a disabilitare il termina sessione
      //this.terminateSessionIsPossible.emit({possibleToFinish: false, responsibleCard: this.selectedDocument.documentTypeId});

      
      this.documentsAlreadyUploadRetrived = true;
      
      this.recoverPhase = true;

      let documentsUploadedToDelete = []

      

      for(let documentUploaded of documentsAlreadyUploaded) {

        if(this.selectedDocument && this.partsFilled < this.selectedDocument.parts.length ) { // Se non ho ancora riempito tutte le 'part' vuote nella scheda
            this.selectedDocument = this.filterDocuments.getDocumentTypeObject(documentUploaded.documentType);
            this.partsFilled++;

            if(this.selectedDocument && documentUploaded.documentType === this.selectedDocument.documentTypeId) { // Se il documento recuperato è uguale a quello che voglio mostrare
              
              //if(this.sessionService.tabClicked && this.typeCard && this.sessionService.tabClicked !== this.typeCard && this.selectedDocument) { // E.. NELLA TAB VISUALIZZATA NULLA è IN CARICAMENTO!
              this.visualizeSpinner();
              //}
              
              
              
              documentsUploadedToDelete.push(documentUploaded);

              let typeOfDocument = documentUploaded.extension === 'pdf' ? 'application/pdf' : 'image/'+documentUploaded.extension

              
              let getDocumentPreviewResponse = this.documentsControllerService.getContent1(this.sessionService.sessionId, documentUploaded.docId, typeOfDocument);
              getDocumentPreviewResponse.subscribe(
                res => {

                  if(this.submissionTypes.includes('IMAGE') && this.submissionTypes.length === 1) { // Nel caso di immagine, utilizza il base64
                  
                    let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);
        
                    this.urls[documentUploaded.documentToward] = base64CompletePreview;
        
                    fetch(this.urls[documentUploaded.documentToward])
                        .then(res => res.blob())
                        .then(blob => {
                          this.imageFiles[documentUploaded.documentToward] = new File([blob], "File name",{ type: typeOfDocument });
        
                          this.docIds[documentUploaded.documentToward] = documentUploaded.docId;

                          this.elabList[documentUploaded.documentToward] = [];

                          this.F5Status = true;
        
                          this.pollingElaborations(documentUploaded.documentToward);
                    });

                  } else if(this.submissionTypes.includes('NATIVE_PDF') && this.submissionTypes.length === 1) {

                    let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);

                    this.urls[documentUploaded.documentToward] = base64CompletePreview; 
      
                    
                    fetch(this.urls[documentUploaded.documentToward].changingThisBreaksApplicationSecurity)
                        .then(res => res.blob())
                        .then(blob => {
                          this.imageFiles[documentUploaded.documentToward] = this.dataURLtoFile(base64CompletePreview);
        
                          this.docIds[documentUploaded.documentToward] = documentUploaded.docId;

                          this.elabList[documentUploaded.documentToward] = [];

                          this.F5Status = true;
        
                          this.pollingElaborations(documentUploaded.documentToward);
                    });
                    
                  } else if(this.submissionTypes.includes('IMAGE') && this.submissionTypes.includes('SCANNED_PDF') && this.submissionTypes.length === 2) {
                    

                    if(this.allowedImagesFormats.includes(typeOfDocument)) { // E' UNA IMMAGINE

                      let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);
        
                      this.urls[documentUploaded.documentToward] = base64CompletePreview;
          
                      fetch(this.urls[documentUploaded.documentToward])
                          .then(res => res.blob())
                          .then(blob => {
                            this.imageFiles[documentUploaded.documentToward] = new File([blob], "File name",{ type: typeOfDocument });
          
                            this.docIds[documentUploaded.documentToward] = documentUploaded.docId;
  
                            this.elabList[documentUploaded.documentToward] = [];

                            this.F5Status = true;
          
                            this.pollingElaborations(documentUploaded.documentToward);
                      });


                    } else if(this.allowedIncomeFormats.includes(typeOfDocument)) { // E' UN PDF

                      

                      let base64CompletePreview = "data:"+typeOfDocument+";base64,"+this.arrayBufferToBase64(res);

                      this.urls[documentUploaded.documentToward] = base64CompletePreview; 
        
                      
                      fetch(this.urls[documentUploaded.documentToward].changingThisBreaksApplicationSecurity)
                          .then(res => res.blob())
                          .then(blob => {
                            this.imageFiles[documentUploaded.documentToward] = this.dataURLtoFile(base64CompletePreview);
          
                            this.docIds[documentUploaded.documentToward] = documentUploaded.docId;
  
                            this.elabList[documentUploaded.documentToward] = [];

                            this.F5Status = true;
          
                            this.pollingElaborations(documentUploaded.documentToward);
                      });                  
                    }

                  }
      
                }, (error) => {
                  this.removeSpinner();
                  this.popupObject = {
                    "type": 'error',
                    "title": "Si è verificato un errore",
                    "description": this.errorService.getErrorDescription(error.status),
                    "choices":['Ok'],
                
                    "newPopup": !this.popupObject.newPopup,
                    "lastPopupSemantic": "Errore dopo chiamata HTTP"
                
                  }
                }
              );



              

            }


        }


      }


      for(let documentToDelete of documentsUploadedToDelete) {
        const index = this.filterDocuments.documentsAlreadyUploaded[this.typeCard].indexOf(documentToDelete);
        if (index > -1) {
          this.filterDocuments.documentsAlreadyUploaded[this.typeCard].splice(index, 1);
        }
      }

      if(this.filterDocuments.documentsAlreadyUploaded[this.typeCard].length > 0) {
        this.alreadyExcluded = true;
        this.filterDocuments.categoriesOfDocumentNotShowed.push(this.selectedDocument);
        for(let i in this.cancella) {
          this.cancella[i] = true;
        }  
        this.createNewCardWithoutDocument.emit(1);
      } else {
        this.recoverPhase = false;
      }

      



    }


    

    

    
  }  


  // Questo metodo toglie da localDocuments (array di tipi di documenti passato in input) i documenti che non devono essere più visualizzati (ovvero quelli in this.filterDocuments.categoriesOfDocumentNotShowed)
  public excludeCategoriesOfDocumentNotShowed(localDocuments) {

    if(localDocuments) {
      if(this.alreadyExcluded) {
        return localDocuments;
      } else {
        const results = localDocuments.filter(({ documentTypeId: id1 }) => !this.filterDocuments.categoriesOfDocumentNotShowed.some(({ documentTypeId: id2 }) => id2 === id1));
        return results;
      }
    }

  }






  // Rende accessibile al template il campo privato del servizio
  public getFilterDocuments() {
    return this.filterDocuments;
  }

  

  // Metodo che evita un errore di Angular
  ngAfterViewChecked(){ 
    this.cdr.detectChanges();
 }


  // Invocato all'inizio e ogni volta che l'utente cambia un documento utilizzando il menu a tendina
  changedSelectedDocument(event) {
    this.loadedPage = true;
    this.selectedDocument = event;
    console.log("this.selectedDocument: ", this.selectedDocument);

 
    for(let type of this.selectedDocument.submissionTypes) {
      this.submissionTypes.push(type.submissionTypeId)
    }
    this.submissionTypes = [...new Set(this.submissionTypes)]; // Elimina tutte le stringhe duplicate
    

    // Resetta tutti i metadati
    this.imageFiles = {};
    this.urls = {};
    this.valueProgressBars = {};
    this.uploadedPhotos = {};
    this.statusAfterUploads = {};
    this.cancella = {};
    this.docIds = {};
    this.labelsPredicted = {};
    this.elabList = {};
    
    this.onePartIsError = false;

    // Cicla per ogni 'part' del documento attualmente selezionato, annullando tutti i metadati relativi alla singola 'part'
    if(this.selectedDocument && this.selectedDocument.parts) {
      for(const part of this.selectedDocument.parts) {

        if(part.active === true) {
          this.imageFiles[part.name] = null;
          this.urls[part.name] = null;
          this.valueProgressBars[part.name] = "0%";
          this.uploadedPhotos[part.name]=false;
          this.statusAfterUploads[part.name]=null;
          this.cancella[part.name]=false;
          this.docIds[part.name]=0;
          this.labelsPredicted[part.name]=null;
          this.elabList[part.name]=null;
  
          this.onePartIsError = false;
        }

      }
    }
  }

  /* Ordina l'array di parts utilizzando le externalDescription in ordine alfabetico */
  public partInLexicalOrder(parts) {
    return parts.sort( this.compare ); 
  }

  /* Funzione di utilità: Ordina l'array di parts utilizzando le externalDescription in ordine alfabetico */
  private compare( a, b ) {
    if ( a.externalDescription < b.externalDescription ){
      return -1;
    }
    if ( a.externalDescription > b.externalDescription ){
      return 1;
    }
    return 0;
  }

  // Funzione di utilità: Data una size in byte la converte nell'unità più "friendly"
  private formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // Wrappa il click di un elemento HTML che è un input di tipo file, relativo al caricamento di un file che può essere sia immagine che PDF
  public buttonUploadClickedIbrid(partId): void {
    $('#file-upload-ibrid-'+partId).click();
  }

  // Wrappa il click di un elemento HTML che è un input di tipo file, relativo al caricamento di una immagina
  public buttonUploadClickedImage(partId): void {
    $('#file-upload-'+partId).click();
  }

  // Wrappa il click di un elemento HTML che è un input di tipo file, relativo al caricamento di un PDF
  public buttonUploadClickedPDF(partId): void {
    $('#file-upload-pdf-'+partId).click();
  }

  mouseEnter(event) {
    this.showTooltip = true;
  }

  mouseLeave(event) {
    this.showTooltip = false;
  }


  /*
  Dopo l'invocazione, se è stata caricata una immagine di formato corretto, vengono valorizzati this.imageFile'Verso' (contenente tutti i metadati del file) 
  e this.url'Verso' (contiene la stringa in base64 per visualizzare l'immagine direttamente nel template HTML).
  Se l'immagine è nel formato corretto, ma è troppo grande, viene riadattata con uno speciale algoritmo preso dalla libreria 'browser-image-compression'
  Se l'immagine non è nel formato corretto, viene mostrato un popup di errore.
  */
  public uploadDocumentImage(event: any, part: any): void {

    console.log("EVENTO: ", event)

    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type; // mimeType è il tipo del documento (formato)
    
    if (this.allowedImagesFormats.includes(mimeType)) {

      if(event.target.files[0].size <= packageInfo.maxImgSize) { // Se la dimensione dell'immagine è entro quella massima consentita
        
        this.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.urls[part.name] = reader.result; 
        }
        
      } else { // Se la dimensione dell'immagine è maggiore di quella consentita
      
        // Comprimi.........

        this.loading = true;
        this.sessionService.blockSession = true;

        var options = {
          maxSizeMB: (packageInfo.maxImgSize/1000000)*0.9,
          useWebWorker: true
        };

        console.log('Starting compression of image, original size=', event.target.files[0].size); // true
        var selfRef = this; // Serve per utilizzare il this dentro la closure

        imageCompression(event.target.files[0], options).then(function (compressedFile) {

            console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
            console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

            selfRef.imageFiles[part.name] = compressedFile;

            var reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onload = (_event) => {
              selfRef.urls[part.name] = reader.result; 
              selfRef.loading = false;
              selfRef.sessionService.blockSession = false;
            }

          }
        ).catch(function (error) {
          this.loading = false;
          this.sessionService.blockSession = false;
          console.log("ERRORE NELLA COMPRESSIONE: ", error);
        });

      }
      

    } else { // Appare un popup di errore      
      this.popupObject = {
        "type": 'error',
        "title": "Si è verificato un errore",
        "description": "Occorre inserire un'immagine in formato JPG, JPEG o PNG.",
        "choices":['Ok'],

        "newPopup": !this.popupObject.newPopup,
        "lastPopupSemantic": "ERRORE - Formato errato"
      }
    }
    
  }

  /*
    -------------------------------------------------------------------------------------
  */
  public uploadDocumentImagePdf(event: any, part: any): void {

    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type; // mimeType è il tipo del documento (formato)
    
    if(this.allowedImagesFormats.includes(mimeType) || this.allowedIncomeFormats.includes(mimeType)) {

      if(this.allowedImagesFormats.includes(mimeType)) { // SE E' UNA IMMAGINE
        if(event.target.files[0].size <= packageInfo.maxImgSize) { // Se la dimensione dell'immagine è entro quella massima consentita
        
          this.imageFiles[part.name] = event.target.files[0];
  
          var reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (_event) => {
            this.urls[part.name] = reader.result; 
          }
          
        } else { // Se la dimensione dell'immagine è maggiore di quella consentita
        
          // Comprimi.........
  
          var options = {
            maxSizeMB: (packageInfo.maxImgSize/1000000)*0.9,
            useWebWorker: true
          };
  
          console.log('Starting compression of image, original size=', event.target.files[0].size); // true
          var selfRef = this; // Serve per utilizzare il this dentro la closure
  
          imageCompression(event.target.files[0], options).then(function (compressedFile) {
  
              console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
              console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
              selfRef.imageFiles[part.name] = compressedFile;
  
              var reader = new FileReader();
              reader.readAsDataURL(compressedFile);
              reader.onload = (_event) => {
                selfRef.urls[part.name] = reader.result; 
              }
  
            }
          ).catch(function (error) {
            console.log("ERRORE NELLA COMPRESSIONE: ", error);
          });
  
        }
      } else { // Se è un PDF

        this.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.urls[part.name] = reader.result; 

          this.urls[part.name] = this.urls[part.name];
        }
      }

    } else { // Appare un popup di errore      
      this.popupObject = {
        "type": 'error',
        "title": "Si è verificato un errore",
        "description": "Occorre inserire un'immagine in formato JPG, JPEG o PNG, o un file in formato PDF",
        "choices":['Ok'],

        "newPopup": !this.popupObject.newPopup,
        "lastPopupSemantic": "ERRORE - Formato errato"
      }
    }
    
  }

  /*
    Dopo l'invocazione, se è stato caricato un PDF di formato corretto, vengono valorizzati this.imageFile'Verso' (contenente tutti i metadati del file) 
    e this.url'Verso' (contiene la stringa in base64 per visualizzare il PDF direttamente nel template HTML).
    Se il PDF non è nel formato corretto, viene visualizzato un messaggio di errore
  */

  pdfIsLoading = false;

  public uploadDocumentNativePDF(event: any, part: any): void {

    this.pdfIsLoading = true;
    this.loading = true; // Il "caricamento apparente" di un file PDF può essere più lungo
    this.sessionService.blockSession = true;

    if(!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }
    var mimeType = event.target.files[0].type;

    if (this.allowedIncomeFormats.includes(mimeType)) {
      this.isScannedOrNot(event, part);
    } else { // Appare un popup di errore

      this.popupObject = {
        "type": 'error',
        "title": "Si è verificato un errore",
        "description": "Occorre inserire un file in formato PDF",
        "choices":['Ok'],

        "newPopup": !this.popupObject.newPopup,
        "lastPopupSemantic": "ERRORE - Formato errato"
      }

      this.pdfIsLoading = false;
      this.loading = false; // Il "caricamento apparente" di un file PDF può essere più lungo
      this.sessionService.blockSession = false;
      
    }
  
  }

    /*
  * Descrizione: Metodo invocato con un file .pdf in input. Esso determina se il file .pdf in input è nativo oppure se è stato scannerizzato con una
                 certa probabilità.
  */
  public async isScannedOrNot(event: any, part: any) {

    if(!event.target.files[0] || event.target.files[0].length == 0) {
        return;
    }
        
    let file = event.target.files[0];

    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    var selfRef = this; // Serve per utilizzare il this dentro la closure

    fileReader.onload = async function() {

      const doc = await pdfjs.getDocument(this.result);
            
      const numPages = await doc.numPages;

      let total_text_area = 0;
      let total_pdf_area = 0;

      for(let i=1; i <= numPages; i++) {

        const page = await doc.getPage(i);
        const textContent = await page.getTextContent();

        let text_area = 0;
        let page_area = page.view[2] * page.view[3]; // L'array page.view ha semantica [left, top, width, height]

        for(let obj of textContent.items) {
          text_area += obj.width * obj.height;
        }

        total_text_area += text_area;
        total_pdf_area += page_area;

      }

      console.log("total_text_area / total_pdf_area: ", total_text_area / total_pdf_area)

      if(total_text_area / total_pdf_area >= packageInfo.thresholdTolerancePDF) { // 0.1 è per adesso la soglia scelta di tolleranza alla quantità di testo scritto dentro il documento .pdf
        console.log("Probabilmente nativo");

        console.log("Il file PDF: ", event.target.files)

        // Serve per visualizzare il PDF dentro la card
        
        selfRef.imageFiles[part.name] = event.target.files[0];

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          selfRef.urls[part.name] = reader.result; 

          selfRef.urls[part.name] = selfRef.urls[part.name]; 

          selfRef.pdfIsLoading = false;
          selfRef.loading = false; // Il "caricamento apparente" di un file PDF può essere più lungo
          selfRef.sessionService.blockSession = false;

        }
        
        
      } else {

        selfRef.loading = false; // Il "caricamento apparente" di un file PDF può essere più lungo
        selfRef.sessionService.blockSession = false;
        selfRef.pdfIsLoading = false;

        let description = 'È stato rilevato un documento PDF non nativo ma costituito da immagini <br /><br />' + 
        'Ti ricordiamo che i documenti reddituali sono solo caricabili tramite <b>PDF nativi</b>';
    
        selfRef.popupObject = {
          "type": 'error',
          "title": "Errore",
          "description": description,
          "choices":['Ok'],
      
          "newPopup": !selfRef.popupObject.newPopup,
          "lastPopupSemantic": "Errore PDF nativo"
      
        }
      }
    
    }

  }
  

  // Il metodo è invocato quando si clicca sul pulsante CARICA. Carica la barra fino a metà e chiama la checkDocumentTypeForm.
  // Costruisce l'array 'labelPredicted' e lo associa al 'part' del documento caricato.
  // Potrebbe mostrare un popup che chiede di continuare l'elaborazione oppure no se le label non combaciano con quanto predetto

  @Output() notAllPartsUploaded = new EventEmitter();


  public async startLoadingPhoto(part: any): Promise<void> {


    


    if(!this.allImageFilesAreNotNull()) {
      this.terminateSessionIsPossible.emit({possibleToFinish: false, responsibleCard: this.selectedDocument.documentTypeId});

    }
    

    this.loading = true;
    this.sessionService.blockSession = true;
    
    this.valueProgressBars[part.name] = "50%";

    let responseCheckDocumentType = this.servicesControllerService.checkDocumentTypeForm(this.imageFiles[part.name]);
    responseCheckDocumentType.subscribe(
      res => {
        console.log("Risposta arrivata: ", res)
        
        let labelPredicted = [];

        for(const i in res) {
          if ('labelPredicted' in res[i]) {
            labelPredicted.push(res[i].labelPredicted);
          }
        }

        console.log("labelPredicted: ", labelPredicted)

        // Controlla se è presente almeno un documentTypeList
        let foundDocumentTypeList = false;
        for(const i in res) {
          if ('documentTypeList' in res[i]) {
            foundDocumentTypeList = true;
            break;
          }
        }

        if(!foundDocumentTypeList) {
          // Mostra il popup: "sei sicuro di caricare? privo dell'ultima parte"
          let whatIExpect = this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + " " + (part.externalDescription);

          let descriptionString = 'Si è sicuri di aver caricato ' + '<b>' + whatIExpect + '</b>?<br />' + 'Continuare lo stesso?';
        
          this.popupObject = {
            "type": 'warning',
            "title": "Attenzione",
            "description": descriptionString,
            "choices":['Si', 'No'],

            "newPopup": !this.popupObject.newPopup,
            "lastPopupSemantic": "ATTENZIONE - Classificazione diversa " + part.externalDescription.toUpperCase()
          };
        } else {

          for(const i in res) {
            if ('documentTypeList' in res[i]) {
              if(res[i].documentTypeList.length > 0) { // Se l'array documentTypeList ha qualcosa
  
                this.labelsPredicted[part.name] = JSON.stringify(labelPredicted);
  
                if(res[i].documentTypeList[0].documentType === this.selectedDocument.documentTypeId && (res[i].documentTypeList[0].documentToward === part.name)) {
  
                  // Bisogna iniziare l'upload utilizzando polling
  
                  this.obtainIdDocumentBeforePolling(part.name);
                  
                } else {
  
                  // Mostra il popup: "sei sicuro di caricare?"
                  let whatIExpect = this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + " " + (part.externalDescription);
                  let whatIsIt = this.filterDocuments.getExternalDescription(res[i].documentTypeList[0].documentType) + " " + (this.filterDocuments.fromNameToExternalDescription(res[i].documentTypeList[0].documentToward)); // TODO: verificare se è possibile modificarlo col sevizio. Serve la funzione discussa con filippo
  
                  let descriptionString;

                  if(!whatIsIt || whatIsIt.includes("null")) {
                    descriptionString = 'Si è sicuri di aver caricato ' + '<b>' + whatIExpect + '</b>?<br />' + 'Continuare lo stesso?';
                  } else {
                    descriptionString = 'Si è sicuri di aver caricato ' + '<b>' + whatIExpect + '</b>?<br />' + 'Sembra essere ' + '<b>' + whatIsIt + '</b>.<br />' + 'Continuare lo stesso?';
                  }


                  
                
                  this.popupObject = {
                    "type": 'warning',
                    "title": "Attenzione",
                    "description": descriptionString,
                    "choices":['Si', 'No'],
  
                    "newPopup": !this.popupObject.newPopup,
                    "lastPopupSemantic": "ATTENZIONE - Classificazione diversa " + part.externalDescription.toUpperCase()
                  };
  
  
                }
                
  
              } else {
                  // Mostra il popup: "sei sicuro di caricare? privo dell'ultima parte"
                  let whatIExpect = this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + " " + (part.externalDescription);
  
                  let descriptionString = 'Si è sicuri di aver caricato ' + '<b>' + whatIExpect + '</b>?<br />' + 'Continuare lo stesso?';
                
                  this.popupObject = {
                    "type": 'warning',
                    "title": "Attenzione",
                    "description": descriptionString,
                    "choices":['Si', 'No'],
    
                    "newPopup": !this.popupObject.newPopup,
                    "lastPopupSemantic": "ATTENZIONE - Classificazione diversa " + part.externalDescription.toUpperCase()
                  };
              }
            }
          }

        }



        
        }, (error) => {

          if(error.status === 404) {
              // Mostra il popup: "sei sicuro di caricare? privo dell'ultima parte"
              let whatIExpect = this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + " " + (part.externalDescription);

              let descriptionString = 'Si è sicuri di aver caricato ' + '<b>' + whatIExpect + '</b>?<br />' + 'Continuare lo stesso?';
            
              this.popupObject = {
                "type": 'warning',
                "title": "Attenzione",
                "description": descriptionString,
                "choices":['Si', 'No'],

                "newPopup": !this.popupObject.newPopup,
                "lastPopupSemantic": "ATTENZIONE - Classificazione diversa " + part.externalDescription.toUpperCase()
              };
          } else {
            this.popupObject = {
              "type": 'error',
              "title": "Si è verificato un errore",
              "description": this.errorService.getErrorDescription(error.status),
              "choices":['Ok'],
          
              "newPopup": !this.popupObject.newPopup,
              "lastPopupSemantic": "Errore dopo chiamata HTTP"
            }
          }
        }
      )

  }

    
  // Questo metodo serve per caricare (effettuare l'upload del documento in remoto) ed ottenere un docId, che è essenziale per il proseguimento
  // e l'ottenimento delle informazioni di questo documento tramite polling
  public async obtainIdDocumentBeforePolling(part_name: string): Promise<void> {

    this.valueProgressBars[part_name] = "100%";

    console.log("PART_NAME: ", part_name)

    let documentModel: DocumentModel = { 
      upfile: this.imageFiles[part_name],
      documentToward: part_name,
      documentType: this.selectedDocument.documentTypeId
    };

    let responseDocuments = this.documentsControllerService.createDocumentForm(
      this.sessionService.sessionId,
      documentModel,
      this.labelsPredicted[part_name]
    );

    responseDocuments.subscribe(
      res => {

        this.docIds[part_name] = res["docId"];

        this.elabList[part_name] = []; // Prepara l'array di elaborazioni, inizializzandolo come vuoto

        this.pollingElaborations(part_name);

        
      }, (error) => {
        this.popupObject = {
          "type": 'error',
          "title": "Si è verificato un errore",
          "description": this.errorService.getErrorDescription(error.status),
          "choices":['Ok'],
      
          "newPopup": !this.popupObject.newPopup,
          "lastPopupSemantic": "Errore dopo chiamata HTTP"
      
        }
      }
    );
  






  }


  // Polling Elaborations
  public async pollingElaborations(part_name) {

    this.loading = true;
    this.sessionService.blockSession = true;

    let docId = this.docIds[part_name];

    let responseElaborations = this.elaborationsControllerService.getElaborations(this.sessionService.sessionId, docId);
    responseElaborations.subscribe(
      res => {        

        let pending = false;

        for(const key in res) {
          if(res[key].elaborationState === "PENDING") { // Se c'è un oggetto pending
            pending = true;
            break;
          }
        }

        if(pending) {
  
          setTimeout(()=>{                        
            this.pollingElaborations(part_name);
          }, 3000);

        
        } else {

          this.F5Status = false;
          
          // Lo stile della barra di progressione cambia
          $('.progress-bar-'+part_name).removeClass('progress-bar-striped');
          $('.progress-bar-'+part_name).addClass('bg-success');
          
          this.uploadedPhotos[part_name] = true;

          
          if(this.allPartAreUploaded()) {
            this.showFinalPopup = true;
          }
            

          for(const key in res) {
            if('elaborationState' in res[key]) { // Se c'è un oggetto pending
              this.elabList[part_name].push(res[key]);
            }
          }

          // ********** Algoritmo di feedback elaborazione **********
          // PRIMA PARTE

          let allElabCompleted = true; // Tutte le elaborazioni sono state completate
          let allElaborationFail = true; // Tutte le elaborazioni hanno fallito
          let allConfidenceBad = true; // Tutte le confidenze delle elaborazioni sono BAD

          for(let i = 0; i < this.elabList[part_name].length; i++) { // Per ogni 'oggetto elaborazione'
            let e = this.elabList[part_name][i]; // e è l'oggetto elaborazione corrente
            if(e.elaborationState === "PENDING") { // TODO: probabilmente questo if-else è da eliminare del tutto
              allElabCompleted = false; // Se qualcuna è in stato di PENDING, allora non è vero che tutte sono state completate [NOTA: qui non ci si dovrebbe mai arrivare]
            } else if(e.elaborationState === "SUCCESS") {
              allElaborationFail = false;
            }

            let validEntitiySize = 0;
            //let elaboration_totConfidence = 0;

            console.log("LE ENTITIES: ", e.entities)
            
            if(e.entities != null) { // Se l'array di entities che è stato ottenuto contiene dei valori
              e.elaboration_totConfidence = 0; // Confidenza totale dell'elaborazione
              for(let k = 0; k < e.entities.length; k++) { // Per ogni entità calcolata nella elaborazione corrente
                
                let en = e.entities[k]; // en è l'entità k-esima
                if(en.confidence != null) {
                  e.elaboration_totConfidence = ((e.elaboration_totConfidence * validEntitiySize) + en.confidence)/(validEntitiySize+1);
                } else if(en.confidence == null && en.required) { // Se è stato restituito null come confidenza della singola entità, allora la confidenza viene settata a 0
                  en.confidence = 0;
                  e.elaboration_totConfidence = ((e.elaboration_totConfidence * validEntitiySize) + en.confidence)/(validEntitiySize+1);
                }
                
                validEntitiySize++;

              }
            }
            console.log("Elaboration " + e.elabId + " in state " + e.elaborationState + ( e.elaboration_totConfidence != null ? " with confidence " + e.elaboration_totConfidence : ""));
            
          }

          
          this.elabList[part_name].forEach(function (elab) {
            if(elab.elaboration_totConfidence >= packageInfo.confidenceTreshold){
              allConfidenceBad = false;
            }
          });
          
          // SECONDA PARTE
          if(allElaborationFail) {
            this.onePartIsError = true;
            this.statusAfterUploads[part_name] = 'ERROR';
          } else {
            if(allConfidenceBad) {
              this.statusAfterUploads[part_name] = 'WARNING';
            } else {
              this.statusAfterUploads[part_name] = 'SUCCESS';
            }
          }


          this.finishUpload();


        }



      }, (error) => {
        this.removeSpinner();
        this.popupObject = {
          "type": 'error',
          "title": "Si è verificato un errore",
          "description": this.errorService.getErrorDescription(error.status),
          "choices":['Ok'],
      
          "newPopup": !this.popupObject.newPopup,
          "lastPopupSemantic": "Errore dopo chiamata HTTP"
      
        }
      }
    )


  }


  // Restituisce true se questo è l'ultimissimo documento
  thisIsTheLastDocument() {
    if(this.filterDocuments.finishedDocuments) {
      for(let value of Object.keys(this.filterDocuments.finishedDocuments)) {
        if(this.filterDocuments.finishedDocuments[value] === false) {
          return false;
        }
      }
  
      return true;
    } else {
      return false;
    }


  }

  

  /* Metodo invocato quando un documento è stato caricato. L'effetto è quello di far comparire un pop-up con 3 opzioni: 
     aggiornare il documento, creare un nuovo documento o terminare la sessione
  */
  public finishUpload(): void {

    this.loading = false;
    this.sessionService.blockSession = false;

    this.removeSpinner();
    
    if(this.allPartAreUploaded() && this.recoverPhase == false) { // TODO: Attenzione ad F5: this.recoverPhase == false || this.documentsAlreadyUpdated.length == 0
      

      this.addItemToSession.emit(this.selectedDocument.externalDescription);
      this.terminateSessionIsPossible.emit({possibleToFinish: true, responsibleCard: this.selectedDocument.documentTypeId});

      console.log("Arrivo dove devo arrivare")

      if(this.excludeCategoriesOfDocumentNotShowed(this.filterDocuments.localDocuments[this.typeCard]).length === 1) { // se è l'ultimo documento locale rimasto: Cambia tab  

        console.log("RAMO IF")


        this.filterDocuments.finishedDocuments[this.typeCard] = true;

        if(this.thisIsTheLastDocument()) { // Se è l'ultimissimo degli ultimissimi, nessun cambia tab

          let descriptionString; 
  
          this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' <b>Aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' Premi su <b>Aggiorna Documento</b> per aggiornare il documento appena caricato, o su <b>Cambia Tab</b> per caricare altre tipologie di documenti.'
  
          console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
          console.log("this.typeCard: ", this.typeCard)
          
          if(this.sessionService.tabClicked === this.typeCard) {
            this.popupObject = {
              "type": 'info',
              "title": "Documento inserito con successo",
              "description": descriptionString,
              "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Termina sessione'] : ['Aggiorna documento'],
      
              "newPopup": !this.popupObject.newPopup,
              "lastPopupSemantic": "Documento caricato"
            };
          }
  
  
        } else {

          let descriptionString; 

          this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' <b>Aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + ' Premi su <b>Aggiorna Documento</b> per aggiornare il documento appena caricato, o su <b>Cambia Tab</b> per caricare altre tipologie di documenti.'
  
          console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
          console.log("this.typeCard: ", this.typeCard)

          if(this.sessionService.tabClicked === this.typeCard) {
            this.popupObject = {
              "type": 'info',
              "title": "Documento inserito con successo",
              "description": descriptionString,
              "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Cambia tab', 'Termina sessione'] : ['Aggiorna documento', 'Cambia tab'],
      
              "newPopup": !this.popupObject.newPopup,
              "lastPopupSemantic": "Documento caricato"
            };
          }

        }

      } else {

        console.log("RAMO ELSE")


        let descriptionString; 
       
        this.sessionService.terminateSessionPossible ? descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + '<br />' + '<b>Carica</b> un nuovo documento, <b>aggiorna</b> il precedente o <b>termina</b> il processo di caricamento.' : descriptionString = 'Documento ' + '<b>' + this.filterDocuments.getExternalDescription(this.selectedDocument.documentTypeId) + '</b>' + ' inserito con successo.' + '<br />' + '<b>Carica</b> un nuovo documento oppure <b>aggiorna</b> il precedente.'

        console.log("this.sessionService.tabClicked: ", this.sessionService.tabClicked);
        console.log("this.typeCard: ", this.typeCard)
        if(this.sessionService.tabClicked === this.typeCard) {

          this.popupObject = {
            "type": 'info',
            "title": "Documento inserito con successo",
            "description": descriptionString,
            "choices": (this.sessionService.terminateSessionPossible)? ['Aggiorna documento', 'Nuovo documento', 'Termina sessione'] : ['Aggiorna documento', 'Nuovo documento'],
    
            "newPopup": !this.popupObject.newPopup,
            "lastPopupSemantic": "Documento caricato"
          };
        }
        

      }

    
    } else if(this.recoverPhase == false) {
      this.terminateSessionIsPossible.emit({possibleToFinish: false, responsibleCard: this.selectedDocument.documentTypeId});
    } else {
      //this.terminateSessionIsPossible.emit({possibleToFinish: false, responsibleCard: this.selectedDocument.documentTypeId});
    }
  
  }



  // Metodo invocato quando viene cliccato il pulsante CANCELLA. Elimina una 'part' di un documento dalla sessione corrente.
  public async deleteDocument(part: any) {

    this.cancella[part.name] = true;

    let docId = this.docIds[part.name];

    let responseDelete = this.documentsControllerService.cancelDocument(this.sessionService.sessionId, docId);
    responseDelete.subscribe(
      res => {
        this.terminateSessionIsPossible.emit({possibleToFinish: false, responsibleCard: this.selectedDocument.documentTypeId});

        this.removeItemToSession.emit(this.selectedDocument.externalDescription)
        this.annulla(part);  

        if(this.allUploadedPhotosAreFalse()) {
          this.deleteDocumentForFinish.emit(this.selectedDocument.documentTypeId);
          //this.terminateSessionIsPossible.emit({possibleToFinish: true, responsibleCard: this.selectedDocument.documentTypeId});
        }

        this.filterDocuments.finishedDocuments[this.typeCard] = false;

      }, (error) => {
        this.popupObject = {
          "type": 'error',
          "title": "Si è verificato un errore",
          "description": this.errorService.getErrorDescription(error.status),
          "choices":['Ok'],
      
          "newPopup": !this.popupObject.newPopup,
          "lastPopupSemantic": "Errore dopo chiamata HTTP"
      
        }
      }
    )

    
  }

  // Metodo invocato quando viene cliccato il pulsante ANNULLA dopo l'upload a Front-end. Non mostra più a schermo l'immagine relativa alla 'part' del documento
  public annulla(part: any) {
    this.imageFiles[part.name] = null;
    this.urls[part.name] = null;
    this.valueProgressBars[part.name] = "0%";
    this.cancella[part.name] = false;
    this.uploadedPhotos[part.name] = false;
    this.statusAfterUploads[part.name] = "";
    this.docIds[part.name] = 0;
    this.labelsPredicted[part.name]=null;
    this.elabList[part.name]=null;

    this.onePartIsError = false;
  }











  // Restituisce true se è visibile a schermo anche soltanto una immagine relativa ad una 'part'.
  public oneImageIsUploadedAtScreen(): boolean {

    let oneImageIsUploaded = false;

    for(let part in this.imageFiles) {
      if(this.imageFiles[part]) {
        oneImageIsUploaded = true;
        break;
      }
    }

    return oneImageIsUploaded;
  }

    
  // Restituisce true se tutte le "part" di un certo documento sono state caricate
  public allPartAreUploaded() {

    let allPartAreUploaded = true;

    for(let part in this.uploadedPhotos) {

      console.log("PART: ", this.uploadedPhotos)

      if(this.uploadedPhotos[part] === false) {
        allPartAreUploaded = false;
        break;
      }
    }

    return allPartAreUploaded;
  
  }


  // Restituisce true se tutti gli oggetti dentro this.imageFiles sono definiti (ovvero non ci sono null o undefined), false altrimenti
  public allImageFilesAreNotNull() {

    let allImageFilesAreNotNull = true;

    for(let part in this.imageFiles) {
      if(!this.imageFiles[part]) {
        allImageFilesAreNotNull = false;
        break;
      }
    }

    return allImageFilesAreNotNull;

  }

    // Restituisce true se tutti gli oggetti dentro this.uploadedPhotos sono falsi
    public allUploadedPhotosAreFalse() {
  
      for(let part in this.uploadedPhotos) {
        if(this.uploadedPhotos[part] === true) {
          return false;
        }
      }
  
      return true;
  
    }


  


















  // Funzione per il popup
  /*
    * Parametri: choice: booleano che indica il risultato della scelta di un utente dopo il click di un popup
    * Descrizione: Questo metodo usa l'informazione 'choice' e la semantica dell'utlimo popup visualizzato (this.popupObject.lastPopupSemantic) per eseguire un appropriato algoritmo
  */
  public selectedChoicePopup(choice: string): void {

    if(this.popupObject.lastPopupSemantic.startsWith('ATTENZIONE - Classificazione diversa') && choice === 'Si') {

      let n = this.popupObject.lastPopupSemantic.split(" ");
      let part = n[n.length - 1];

      this.obtainIdDocumentBeforePolling(this.filterDocuments.fromExternalDescriptionToName(this.titleCase(part)));

    } else if(this.popupObject.lastPopupSemantic.startsWith('ATTENZIONE - Classificazione diversa') && choice === 'No') {

      let n = this.popupObject.lastPopupSemantic.split(" ");
      let part = n[n.length - 1];
      this.valueProgressBars[this.filterDocuments.fromExternalDescriptionToName(this.titleCase(part))] = "0%";
      
      this.loading = false;
      this.sessionService.blockSession = false;

    } else if(this.popupObject.lastPopupSemantic.startsWith('Documento caricato') && choice === 'Aggiorna documento') {
      this.showFinalPopup = false;

      this.alreadyExcluded = false;

      // Il documento attuale (this.selectedDocument) va tolto da in un array ideale che tiene traccia dei documenti già uploadati
      const index = this.filterDocuments.categoriesOfDocumentNotShowed.indexOf(this.selectedDocument);
      if (index > -1) {
        this.filterDocuments.categoriesOfDocumentNotShowed.splice(index, 1);
      }

      for(let i in this.cancella) {
        this.cancella[i] = false;
      }

    } else if(this.popupObject.lastPopupSemantic.startsWith('Documento caricato') && choice === 'Nuovo documento') {
      
      this.alreadyExcluded = true;

      // Il documento attuale (this.selectedDocument) va inserito in un array ideale che tiene traccia dei documenti già uploadati
      this.filterDocuments.categoriesOfDocumentNotShowed.push(this.selectedDocument);

      for(let i in this.cancella) {
        this.cancella[i] = true;
      }


      // Viene mandato un evento al padre per creare una nuova card (1 indica il numero nuovo di card da aggiungere)
      this.createNewCardWithoutDocument.emit(1);
      //this.sessionService.firstTryOfSession = false;

    }else if(this.popupObject.lastPopupSemantic.startsWith('Documento caricato') && choice === 'Termina sessione') {
      this.terminateSession.emit();
    } else if(this.popupObject.lastPopupSemantic.startsWith('Documento caricato') && choice === 'Cambia tab') {
      this.alreadyExcluded = true;

      // Il documento attuale (this.selectedDocument) va inserito in un array ideale che tiene traccia dei documenti già uploadati
      this.filterDocuments.categoriesOfDocumentNotShowed.push(this.selectedDocument);

      for(let i in this.cancella) {
        this.cancella[i] = true;
      }

    }

  }

  // Metodo di utilità che formatta una stringa maiuscola in una con la prima maiuscola e le altre minuscole
  private titleCase(str) {
    return str.split(' ').map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
  }


  // Restituisce true se tutte le parts del documento attuale hanno 'active' a false; false altrimenti
  public allPartsAreNotVisible() {

    for(let part of this.selectedDocument.parts) {
      if(part.active === true) {
        return false;
      }
    }

    return true;

  }

  firstTimeNewSession = true;

  showErrorPopupSession() {

    if(this.filterDocuments && this.filterDocuments.statusOfSession && this.firstTimeNewSession === true && this.filterDocuments.statusOfSession !== 'ACTIVE' && this.filterDocuments.statusOfSession !== 'ACTIVE_EMPTY') {
      
      this.firstTimeNewSession = false;

      this.popupObject = {
        "type": 'error',
        "title": "Si è verificato un errore",
        "description": 'Sessione di caricamento non valida. Procedere alla creazione di una nuova sessione',
        "choices":[],
    
        "newPopup": !this.popupObject.newPopup,
        "lastPopupSemantic": "Errore dopo chiamata HTTP"
    
      }

    }


  }


  private removeSpinner() { // TODO: mettere
    //this.element.classList.remove('blur');
    //this.loadingSpinner.classList.add('displayLoading');
  }

  private visualizeSpinner() {
    //this.element.className = 'blur'
    //this.loadingSpinner.classList.remove('displayLoading');
  }

  private trimIfIsLongString(nameFile: string, maxSize: number) {
    let responseString;
    if(nameFile.length > maxSize) {
      responseString = nameFile.substring(0, maxSize);
      responseString = responseString + "...";
    } else {
      responseString = nameFile;
    }

    return responseString;
  } 

  private dataURLtoFile(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "File name", {type:mime});
  }






}
