import { Component, OnInit } from '@angular/core';
import { SessionService } from 'app/services/session.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  selectedTab;

  constructor(private sessionService : SessionService) {

    

  }

  ngOnInit() {
    this.sessionService.obtainSessionId();
  }

  // Metodo setter per selectedTab
  public setSelectedTab(tabName: string): void {
  
    this.selectedTab = tabName;

  }

}
