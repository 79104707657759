import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import packageInfo from '../../config.json';
import { MainComponent } from './components/main/main.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { RedirectAfterSessionIdComponent } from './components/redirect-after-session-id/redirect-after-session-id.component';
import { ResultPageComponent } from './components/result-page/result-page.component';
import { FinishElabComponent } from './components/finish-elab/finish-elab.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

const routes: Routes = [

  
  { path: '', component: RedirectAfterSessionIdComponent},
  { path: 'DocumentsUpload', component: MainComponent},
  { path: 'resultPage', component: ResultPageComponent},
  { path: 'qrCode', component: QrCodeComponent},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: 'success', component: FinishElabComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }