import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  getErrorDescription(error_status) {

    let errorToDisplay = '';

    switch(error_status) {

      case 401:
				errorToDisplay += "Sessione di caricamento non valida. Procedere alla creazione di una nuova sessione.";
				break;
			case 404:
				errorToDisplay += "Una o più risorse non trovate.";
				break;
			case 500:
			case 502:
				errorToDisplay += "Si è verificato un errore nella comunicazione con il server. Provare a ricaricare la pagina";
				break;
			case 504:
				errorToDisplay += "Timeout nella comunicazione con il server.";
				break;			
			default:
				errorToDisplay += "Si è verificato un errore nella comunicazione con il server.";
				break;
    }

    return errorToDisplay;

  }


}
