import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsControllerService, SessionsControllerService } from 'app/api';
import { FilterDocumentsService } from 'app/services/filter-documents.service';
import { SessionService } from 'app/services/session.service';


@Component({
  selector: 'app-tab-document',
  templateUrl: './tab-document.component.html',
  styleUrls: ['./tab-document.component.css']
})
export class TabDocumentComponent implements OnInit {

  @Output() alreadyCreated = new EventEmitter(); // Evento invocato soltanto una volta al parent dopo la creazione del componente. In questo modo il parent non userà più *ngIf per caricare il componente, se non la prima volta

  @Input() public typeCards: string; // Indicherà la tipologia di documenti associati alla tab (Identity, Income, Other)

  cardsNumber: number = 1; // Numero di card presenti a schermo

  public completedDocumentsDescriptions = []; // Array contenente le descrizioni (in italiano) dei documenti già caricati per la sessione corrente

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
    
    "type" : null,
    "title" : "PopupTabDocument",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  constructor(private filterDocuments : FilterDocumentsService,
    private sessionService : SessionService,
    private documentsControllerService: DocumentsControllerService,
    private cdr: ChangeDetectorRef,
    private sessionsControllerService: SessionsControllerService,
    private router: Router) {    
  }

  ngOnInit() {
    this.alreadyCreated.emit(this.typeCards);

  }

  // Metodo che evita un errore di Angular
  ngAfterViewChecked(){ 
      this.cdr.detectChanges();
   }



  // Rende accessibile al template il campo privato del servizio
  public getFilterDocuments() {
    return this.filterDocuments;
  }
/*
  public changeCardsNumber(newCardsNumber) {
    this.cardsNumber = newCardsNumber;
  }
  */

  public addNewCard(numberOfNewCards) {
    this.cardsNumber = this.cardsNumber + numberOfNewCards;
  }

  @Output() terminateSessionPossibleEvent = new EventEmitter();

  // Setta lo stato della sentinella 'terminateSessionPossible' se dalle cards arriva un evento che specifica che è possibile terminare la sessione
  public changeStatusTerminateSession(isPossibleTerminate) {
    this.terminateSessionPossibleEvent.emit(isPossibleTerminate);
  }

  @Output() addItemToTheSessionTotal = new EventEmitter();

  addItemToTheSession(addItemToTheSessionTotal) {

    // EMETTE L'EVENTO AL PARENT PER AGGIUNGERE ITEM ALLA SESSIONE
    this.addItemToTheSessionTotal.emit(addItemToTheSessionTotal);
    
    //this.completedDocumentsDescriptions.push(document_description);
  }

  @Output() removeItemToTheSessionTotal = new EventEmitter();

  removeItemToTheSession(document_description) {


    this.removeItemToTheSessionTotal.emit(document_description);


  }

  @Output() deleteDocumentForFinish2 = new EventEmitter();

  deleteStatusTerminateSession(value) {

    this.deleteDocumentForFinish2.emit(value);

  }


  @Output() terminateSessionTotally = new EventEmitter();

  terminateSession() {
    this.terminateSessionTotally.emit();
  }








  

  

}
