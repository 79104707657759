import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ConfigurationsControllerService } from './api/configurationsController.service';
import { CrossCheckControllerService } from './api/crossCheckController.service';
import { DocumentsControllerService } from './api/documentsController.service';
import { ElaborationsControllerService } from './api/elaborationsController.service';
import { ServicesControllerService } from './api/servicesController.service';
import { SessionsControllerService } from './api/sessionsController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ConfigurationsControllerService,
    CrossCheckControllerService,
    DocumentsControllerService,
    ElaborationsControllerService,
    ServicesControllerService,
    SessionsControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
