import { Component, OnInit } from '@angular/core';
import { FilterDocumentsService } from './services/filter-documents.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  constructor() {

  }
/*
  public getFilterDocumentsService() {
    return this.filterDocumentsService;
  }
  */

}
