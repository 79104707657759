export * from './configurationsController.service';
import { ConfigurationsControllerService } from './configurationsController.service';
export * from './crossCheckController.service';
import { CrossCheckControllerService } from './crossCheckController.service';
export * from './documentsController.service';
import { DocumentsControllerService } from './documentsController.service';
export * from './elaborationsController.service';
import { ElaborationsControllerService } from './elaborationsController.service';
export * from './servicesController.service';
import { ServicesControllerService } from './servicesController.service';
export * from './sessionsController.service';
import { SessionsControllerService } from './sessionsController.service';
export const APIS = [ConfigurationsControllerService, CrossCheckControllerService, DocumentsControllerService, ElaborationsControllerService, ServicesControllerService, SessionsControllerService];
