/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LabelPrediction { 
    labelName?: string;
    label?: LabelPrediction.LabelEnum;
    score?: number;
}
export namespace LabelPrediction {
    export type LabelEnum = 'CIE_2_0' | 'CIE_2_0_BACK' | 'CIE_2_0_FRONT' | 'CIE_3_0' | 'CIE_3_0_BACK' | 'CIE_3_0_FRONT' | 'DRIVING_LICENCE' | 'DRIVING_LICENCE_BACK' | 'DRIVING_LICENCE_FRONT' | 'FISCAL_CODE' | 'FISCAL_CODE_BACK' | 'FISCAL_CODE_FRONT' | 'IDENTITY_DOCUMENT' | 'ID_CARD' | 'ID_CARD_BACK' | 'ID_CARD_FRONT' | 'REPLY_ID' | 'REPLY_ID_FRONT' | 'REPLY_ID_BACK' | 'PAYSLIP' | 'PAYSLIP_ZUCCHETTI' | 'PAYSLIP_ZUCCHETTI_NTI_SECTION' | 'PAYSLIP_ALL_PAGE' | 'PAYSLIP_NTI_SECTION' | 'PAYSLIP_ZUCCHETTI_NET_SECT' | 'PAYSLIP_ZUCCHETTI_ALL_PAGE' | 'PAYSLIP_ZUCCHETTI_GROSS_SECTION' | 'PAYSLIP_MEF' | 'PAYSLIP_MEF_ALL_PAGE' | 'PAYSLIP_MEF_ALL_PAGE_SUMMARY' | 'PAYSLIP_MEF_ALL_PAGE_DETAIL' | 'PAYSLIP_MEF_NTI_SECTION' | 'PAYSLIP_MEF_NTI_SUMMARY_SECTION' | 'PAYSLIP_MEF_NTI_DETAIL_SECTION' | 'PAYSLIP_MEF_SUMMARY_GROSS_SEC' | 'PAYSLIP_MEF_SUMMARY_NET_SECT' | 'PAYSLIP_MEF_SUMMARY_FIFHT_SECT' | 'PAYSLIP_MEF_DETAIL_NET_SECT' | 'PAYSLIP_MEF_DETAIL_GROSS_SEC' | 'VEHICLE_REGISTRATION_DOC' | 'VEHICLE_REGISTRATION_DOC_FRONT' | 'VEHICLE_REGISTRATION_DOC_BACK' | 'PERSONE_FISICHE' | 'PERSONE_FISICHE_MAIN' | 'CERTIFICAZIONE_UNICA' | 'CERTIFICAZIONE_UNICA_MAIN';
    export const LabelEnum = {
        CIE20: 'CIE_2_0' as LabelEnum,
        CIE20BACK: 'CIE_2_0_BACK' as LabelEnum,
        CIE20FRONT: 'CIE_2_0_FRONT' as LabelEnum,
        CIE30: 'CIE_3_0' as LabelEnum,
        CIE30BACK: 'CIE_3_0_BACK' as LabelEnum,
        CIE30FRONT: 'CIE_3_0_FRONT' as LabelEnum,
        DRIVINGLICENCE: 'DRIVING_LICENCE' as LabelEnum,
        DRIVINGLICENCEBACK: 'DRIVING_LICENCE_BACK' as LabelEnum,
        DRIVINGLICENCEFRONT: 'DRIVING_LICENCE_FRONT' as LabelEnum,
        FISCALCODE: 'FISCAL_CODE' as LabelEnum,
        FISCALCODEBACK: 'FISCAL_CODE_BACK' as LabelEnum,
        FISCALCODEFRONT: 'FISCAL_CODE_FRONT' as LabelEnum,
        IDENTITYDOCUMENT: 'IDENTITY_DOCUMENT' as LabelEnum,
        IDCARD: 'ID_CARD' as LabelEnum,
        IDCARDBACK: 'ID_CARD_BACK' as LabelEnum,
        IDCARDFRONT: 'ID_CARD_FRONT' as LabelEnum,
        REPLYID: 'REPLY_ID' as LabelEnum,
        REPLYIDFRONT: 'REPLY_ID_FRONT' as LabelEnum,
        REPLYIDBACK: 'REPLY_ID_BACK' as LabelEnum,
        PAYSLIP: 'PAYSLIP' as LabelEnum,
        PAYSLIPZUCCHETTI: 'PAYSLIP_ZUCCHETTI' as LabelEnum,
        PAYSLIPZUCCHETTINTISECTION: 'PAYSLIP_ZUCCHETTI_NTI_SECTION' as LabelEnum,
        PAYSLIPALLPAGE: 'PAYSLIP_ALL_PAGE' as LabelEnum,
        PAYSLIPNTISECTION: 'PAYSLIP_NTI_SECTION' as LabelEnum,
        PAYSLIPZUCCHETTINETSECT: 'PAYSLIP_ZUCCHETTI_NET_SECT' as LabelEnum,
        PAYSLIPZUCCHETTIALLPAGE: 'PAYSLIP_ZUCCHETTI_ALL_PAGE' as LabelEnum,
        PAYSLIPZUCCHETTIGROSSSECTION: 'PAYSLIP_ZUCCHETTI_GROSS_SECTION' as LabelEnum,
        PAYSLIPMEF: 'PAYSLIP_MEF' as LabelEnum,
        PAYSLIPMEFALLPAGE: 'PAYSLIP_MEF_ALL_PAGE' as LabelEnum,
        PAYSLIPMEFALLPAGESUMMARY: 'PAYSLIP_MEF_ALL_PAGE_SUMMARY' as LabelEnum,
        PAYSLIPMEFALLPAGEDETAIL: 'PAYSLIP_MEF_ALL_PAGE_DETAIL' as LabelEnum,
        PAYSLIPMEFNTISECTION: 'PAYSLIP_MEF_NTI_SECTION' as LabelEnum,
        PAYSLIPMEFNTISUMMARYSECTION: 'PAYSLIP_MEF_NTI_SUMMARY_SECTION' as LabelEnum,
        PAYSLIPMEFNTIDETAILSECTION: 'PAYSLIP_MEF_NTI_DETAIL_SECTION' as LabelEnum,
        PAYSLIPMEFSUMMARYGROSSSEC: 'PAYSLIP_MEF_SUMMARY_GROSS_SEC' as LabelEnum,
        PAYSLIPMEFSUMMARYNETSECT: 'PAYSLIP_MEF_SUMMARY_NET_SECT' as LabelEnum,
        PAYSLIPMEFSUMMARYFIFHTSECT: 'PAYSLIP_MEF_SUMMARY_FIFHT_SECT' as LabelEnum,
        PAYSLIPMEFDETAILNETSECT: 'PAYSLIP_MEF_DETAIL_NET_SECT' as LabelEnum,
        PAYSLIPMEFDETAILGROSSSEC: 'PAYSLIP_MEF_DETAIL_GROSS_SEC' as LabelEnum,
        VEHICLEREGISTRATIONDOC: 'VEHICLE_REGISTRATION_DOC' as LabelEnum,
        VEHICLEREGISTRATIONDOCFRONT: 'VEHICLE_REGISTRATION_DOC_FRONT' as LabelEnum,
        VEHICLEREGISTRATIONDOCBACK: 'VEHICLE_REGISTRATION_DOC_BACK' as LabelEnum,
        PERSONEFISICHE: 'PERSONE_FISICHE' as LabelEnum,
        PERSONEFISICHEMAIN: 'PERSONE_FISICHE_MAIN' as LabelEnum,
        CERTIFICAZIONEUNICA: 'CERTIFICAZIONE_UNICA' as LabelEnum,
        CERTIFICAZIONEUNICAMAIN: 'CERTIFICAZIONE_UNICA_MAIN' as LabelEnum
    };
}