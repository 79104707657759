import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SessionService } from 'app/services/session.service';
import * as $ from 'jquery';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    id: string;
    
}

export const ROUTES: RouteInfo[] = [

    { path: '/',     title: 'Documenti di Identità',         icon:'nc-credit-card', id:'identity' },
    { path: '/',     title: 'Documenti reddituali',         icon:'nc-paper', id:'income' },

    { path: '/',     title: 'Altro',         icon:'nc-single-copy-04', id:'other' },


    
    //{ path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
    //{ path: '/icons',         title: 'Icons',             icon:'nc-diamond',    class: '' },
    //{ path: '/maps',          title: 'Maps',              icon:'nc-pin-3',      class: '' },
    //{ path: '/notifications', title: 'Notifications',     icon:'nc-bell-55',    class: '' },
    //{ path: '/user',          title: 'User Profile',      icon:'nc-single-02',  class: '' },
    //{ path: '/table',         title: 'Table List',        icon:'nc-tile-56',    class: '' },
    //{ path: '/typography',    title: 'Typography',        icon:'nc-caps-small', class: '' },
    //{ path: '/upgrade',       title: 'Upgrade to PRO',    icon:'nc-spaceship',  class: 'active-pro' },
    
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

    public menuItems: any[];
    
    @Output() emitSelectedTab = new EventEmitter(); // Emette verso il parent la stringa della tab cliccata TODO: vedere se si può ancora fare

    constructor(private sessionService: SessionService) { }

    
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }


    actualTab: string;

    public showSection(menuItem) {


      this.actualTab = menuItem.title;

      let element = document.getElementById(menuItem.id);

      if(menuItem.title === 'Documenti di Identità') {
          if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {
              if(!$('#identity').hasClass( "sidebar_not_allowed" )) {

                this.sessionService.tabClicked = 'Identity'

                $('#' + 'identity' + '-i').addClass('sidebarClicked');
                $('#' + 'identity' + '-p').addClass('sidebarClicked');

                $('#' + 'income' + '-i').removeClass('sidebarClicked');
                $('#' + 'income' + '-p').removeClass('sidebarClicked');

                $('#' + 'other' + '-i').removeClass('sidebarClicked');
                $('#' + 'other' + '-p').removeClass('sidebarClicked');
          
                this.emitSelectedTab.emit("Identity");
          
              }
            }
      } else if(menuItem.title === 'Documenti reddituali') {
          if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {

              if(!$('#income').hasClass( "sidebar_not_allowed" )) {

                this.sessionService.tabClicked = 'Income'

                $('#' + 'identity' + '-i').removeClass('sidebarClicked');
                $('#' + 'identity' + '-p').removeClass('sidebarClicked');

                $('#' + 'income' + '-i').addClass('sidebarClicked');
                $('#' + 'income' + '-p').addClass('sidebarClicked');

                $('#' + 'other' + '-i').removeClass('sidebarClicked');
                $('#' + 'other' + '-p').removeClass('sidebarClicked');
            
                this.emitSelectedTab.emit("Income");
              }
            }
      } else if(menuItem.title === 'Altro') {
          if(!this.sessionService.blockSession && !this.sessionService.clickedTerminateSession) {
              if(!$("#other").hasClass( "sidebar_not_allowed" )) {

                this.sessionService.tabClicked = 'Other'
        
                $('#' + 'identity' + '-i').removeClass('sidebarClicked');
                $('#' + 'identity' + '-p').removeClass('sidebarClicked');

                $('#' + 'income' + '-i').removeClass('sidebarClicked');
                $('#' + 'income' + '-p').removeClass('sidebarClicked');

                $('#' + 'other' + '-i').addClass('sidebarClicked');
                $('#' + 'other' + '-p').addClass('sidebarClicked');
          
                this.emitSelectedTab.emit("Other");
              }
            }
      }



    }
}
