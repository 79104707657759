import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'cards-container-component',
  templateUrl: './cards-container.component.html',
  styleUrls: ['./cards-container.component.css']
})
export class CardsContainerComponent implements OnInit {

  @Input() group: any; // Indicherà la tipologia di documenti associati alla tab (Identity, Income, Other)

  cardsNumber: number = 1; // Numero di card presenti a schermo

  constructor() { }

  ngOnInit(): void {
  }

  public addNewCard(numberOfNewCards) {
    this.cardsNumber = this.cardsNumber + numberOfNewCards;
  }

}
