import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
    
    "type" : null,
    "title" : "PopupTabDocument",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  // Metodo che evita un errore di Angular
  ngAfterViewChecked(){ 
    this.cdr.detectChanges();
   }

  showedPopup = false;

  showPopup() {

    this.showedPopup = true;

    this.popupObject = {
      "type": 'error',
      "title": "Accesso negato!",
      "description": "Sessione scaduta o link di accesso non valido. Avvia una nuova sessione per poter procedere all'acquisizione documentale.<br>",
      "choices":[],
  
      "newPopup": !this.popupObject.newPopup,
      "lastPopupSemantic": "Errore dopo chiamata HTTP"
  
    }
  }

}
