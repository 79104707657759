import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrowserQRCodeSvgWriter } from '@zxing/browser';

import packageInfo from '../../../../config.json';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
    "type" : null,
    "title" : "PopupWrongQrCode",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""
  };


    constructor(private activatedRoute: ActivatedRoute) {}
  
    ngOnInit(): void {
      this.obtainSessionId();
    }


    private async obtainSessionId(): Promise<void> {

      let responseActivatedRoute = this.activatedRoute.queryParams;
      responseActivatedRoute.subscribe(
        params => {
          
          if((Object.keys(params).length != 0) && ('sessionId' in params) && ('otp' in params)) { // Se ci sono dei query params che sono sessionId e otp
        
            
            let urlString = packageInfo.apiGatewayUrl + "/" + packageInfo.env + "/docIDRest/rest/sessions/" + params.sessionId + "/accessToken?otp=" + params.otp;
            
            const codeWriter = new BrowserQRCodeSvgWriter();
            const newLocal = '#container-result';
            codeWriter.writeToDom(newLocal, urlString, 300, 300);
        
          } else {
  
            this.popupObject = {
              "type": 'error',
              "title": "Si è verificato un errore",
              "description": "Stiamo riscontrando problemi sulla piattaforma, ti preghiamo di riprovare più tardi",
              "choices":['Ok'],
      
              "newPopup": !this.popupObject.newPopup,
              "lastPopupSemantic": "ERRORE - Formato errato"
            }
            
  
          }

        }, (error) => {

          this.popupObject = {
            "type": 'error',
            "title": "Si è verificato un errore",
            "description": "Stiamo riscontrando problemi sulla piattaforma, ti preghiamo di riprovare più tardi",
            "choices":['Ok'],
    
            "newPopup": !this.popupObject.newPopup,
            "lastPopupSemantic": "ERRORE - Formato errato"
          }

        }
      );
      
    }

    // Scelta del popuo
    selectedChoicePopup(choice: string) {


    }


}


