import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finish-elab',
  templateUrl: './finish-elab.component.html',
  styleUrls: ['./finish-elab.component.css']
})
export class FinishElabComponent implements OnInit {

  public popupObject: any = {  // Oggetto contenente le informazioni essenziali per la customizzazione di un popup che appare a schermo dopo un qualche evento
    
    "type" : null,
    "title" : "PopupTabDocument",
    "description" :  null,
    "choices" : [],

    "newPopup" : false,
    "lastPopupSemantic": ""

  };

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  // Metodo che evita un errore di Angular
  ngAfterViewChecked(){ 
    this.cdr.detectChanges();
   }

  showedPopup = false;

  showPopup() {

    this.showedPopup = true;

    this.popupObject = {
      "type": 'info',
      "title": "Operazione completata",
      "description": "Il caricamento documentale si è concluso con successo, è possibile chiudere la pagina.",
      "choices":[],
  
      "newPopup": !this.popupObject.newPopup,
      "lastPopupSemantic": "Errore dopo chiamata HTTP"
  
    }
  }

}
