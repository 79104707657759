import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  selectedTab;

  ngOnInit() { }

  // Metodo setter per selectedTab
  public setSelectedTab(tabName: string): void {

    this.selectedTab = tabName;

  }
  
}
