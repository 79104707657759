import { Injectable } from '@angular/core';
import { ConfigurationsControllerService, DocumentsControllerService, SessionsControllerService } from 'app/api';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class FilterDocumentsService {

  // TODO: questo servizio potrebbe tenere conto di F5 e in generale dovrebbe fare tutti i calcoli solo una volta e all'inizio

  public initialDocuments: any[]; // Costituisce l'elenco di tutti i documenti recuperati inizialmente 

  public globalDocuments: any[]; // Costituisce l'elenco di tutti i documenti recuperati inizialmente filtrati in base ai documenti della sessione corrente

  public localDocuments: any = {}; // Dizionario in cui ad una chiave è associata una categoria di documenti (sottoinsieme di this.globalDocuments)
  
  public documentsAlreadyUploaded: any = {}; // Dizionario in cui ad una chiave è associata una categoria di documenti CHE E' STATA RECUPERATA PERCHè CARICATA IN PASSATO

  public cardsOfThisCategory = {}; // Dizionario in cui ad una chiave che rappresenta una categoria, è associato un numero di cards da inserire nella corrispondente TAB

  private allPartsObtained = {}; // Dizionario che dato un 'name' di un oggetto 'part', restituisce una 'externalDescription'


  public categoriesOfDocumentNotShowed = []; // Serve perchè quando si crea una nuova card, essa non deve avere i tipi di documenti elencati qui dentro

  public statusOfSession; // Valore che indica lo stato della sessione corrente


  public finishedDocuments: any = {}; // Dizionario che associa ad una chiave il booleano true per indicare che ho finito l'upload di quella categoria, false altrimenti


  constructor(
    private configurationsControllerService: ConfigurationsControllerService,
    private documentsControllerService: DocumentsControllerService,
    private sessionService: SessionService,
    private sessionsControllerService: SessionsControllerService
  ) {


    this.finishedDocuments["Identity"] = false;
    this.finishedDocuments["Income"] = false;
    this.finishedDocuments["Other"] = false;

    this.obtainDocuments();
  }

  private async obtainDocuments(): Promise<void> {
    
    if(!this.globalDocuments) {

      let responseDocumentTypes = this.configurationsControllerService.getDocumentTypes();
      responseDocumentTypes.subscribe(
        async res => {

          console.log("responseDocumentTypes: ", res)

          this.initialDocuments = res;

          let responseGetSession = this.sessionsControllerService.getSession(this.sessionService.sessionId);
          responseGetSession.subscribe(
            async ress => {

              console.log("RESSS: ", ress)

              this.statusOfSession = ress.state;
              this.globalDocuments = this.getSubsetOfGlobalDocuments(res, ress.docTypes);
              
              console.log("I globalDocuments: ", this.globalDocuments)
              this.localDocuments["Identity"] = this.filterDocumentsByGroup("IDENTITY_DOCUMENTS");
              this.localDocuments["Income"] = this.filterDocumentsByGroup("INCOME_DOCUMENTS");
              this.localDocuments["Other"] = this.filterDocumentsByGroup("OTHER");
    
              for(let document in this.globalDocuments) {
                if(this.globalDocuments[document].parts) {
          
                  for(let part in this.globalDocuments[document].parts) {
                    this.allPartsObtained[this.globalDocuments[document].parts[part].name] = this.globalDocuments[document].parts[part].externalDescription
                  }
          
                }
              }

    
              // Da qui in poi è gestito l'F5 per il recupero di documenti vecchi

              if(this.statusOfSession === 'ACTIVE') {
                let getDocumentsResponse = this.documentsControllerService.getDocuments(this.sessionService.sessionId);
                getDocumentsResponse.subscribe(
                  res => {
      
                    this.documentsAlreadyUploaded["Identity"] = this.filterDocumentsAlreadyUploaded(res, "Identity");
                    this.documentsAlreadyUploaded["Income"] = this.filterDocumentsAlreadyUploaded(res, "Income");
                    this.documentsAlreadyUploaded["Other"] = this.filterDocumentsAlreadyUploaded(res, "Other");
      
                    this.cardsOfThisCategory["Identity"] = this.getCardsNumberOfThisCategory("Identity");
                    this.cardsOfThisCategory["Income"] = this.getCardsNumberOfThisCategory("Income");
                    this.cardsOfThisCategory["Other"] = this.getCardsNumberOfThisCategory("Other");
      
                  }, (error) => {
                    console.log("ERRORE GET_DOCUMENTS: ", error)
                  }
                );
              }
    
              





            }, (error) => {
              console.log("ERROR: ", error)
            }
          );

        }, (error) => {
          console.log("ERRORE getDocumentTypes: ", error)
        }
      ) 

    }
    
  }


  // Restituisce un sottoinsieme di tipi di documenti filtrato per un array contenente solo il nome dei tipi
  private getSubsetOfGlobalDocuments(allDocumentsType,subsetTypes) {

    let subset = []; // Qui dentro vengono salvati i tipi di documenti originariamente dentro ad 'allDocumentsType'

    if(this.isIterable(allDocumentsType)) {
      for(let documentType of allDocumentsType) {
        if(subsetTypes.includes(documentType.documentTypeId) && !this.allPartsAreNotVisible(documentType)) {
          subset.push(documentType);
        }
      }
    }


    return subset;

  }

  public isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  // Restituisce true se tutte le parts del documento attuale hanno 'active' a false; false altrimenti
  public allPartsAreNotVisible(doc) {

    for(let part of doc.parts) {
      if(part.active === true) {
        return false;
      }
    }

    return true;

  }


  // In base al tipo di stringa groupId restituisce la tipologia di documenti (es: 'INCOME_DOCUMENTS', 'IDENTITY_DOCUMENTS')
  private filterDocumentsByGroup(groupId: string): any[] {

    let groupDocuments = [];
    for(const doc of this.globalDocuments) {
      if(doc.group.groupId === groupId) {
        groupDocuments.push(doc);
      }
    }

    return groupDocuments;

  }


  // Metodo che sulla base di una stringa che indica una tipologia di documenti (Identity, Income, Other) restituisce il numero di cards che deve avere la corrispondente tab
  private getCardsNumberOfThisCategory(groupId: string) {
    let documentTypes = []; // Array che conterrà tutte le stringhe 'documentType' estrapolate dagli oggetti contenuti in res
    for(const document of this.documentsAlreadyUploaded[groupId]) {
      documentTypes.push(document.documentType)
    }
    return [...new Set(documentTypes)]; // Elimina tutte le stringhe duplicate per ottenere il numero esatto di card!
  }


  // Metodo che sulla base di una stringa che indica una tipologia di documenti (Identity, Income, Other) restituisce il sotto-elenco di documenti già caricati
  private filterDocumentsAlreadyUploaded(documentsAlreadyUploaded: any[], groupId: string) {

    let documentsTypes = []; // Array contenente tutti i 'documentTypeId' di una certa categoria (Identity)
    for(const document of this.localDocuments[groupId]) {
      documentsTypes.push(document.documentTypeId)
    }

    let createList = []; // Array contenente gli oggetti documenti completi che sono stati recuperati da getDocuments, ma filtrati per categoria (Identity)
    for(const document of documentsAlreadyUploaded) {
      if(documentsTypes.includes(document.documentType)) {
        createList.push(document);
      }
    }

    return createList;
    
  }


  // Descrizione: dato un 'documentTypeId', questo metodo restituisce la stringa descrittiva (in italiano) di quel documento (es: Carta di identità cartacea)
  public getExternalDescription(documentTypeId: string): string {
    for(const key in this.initialDocuments) {
      if(this.initialDocuments[key].documentTypeId === documentTypeId) {        
        return this.initialDocuments[key].externalDescription;
      }
    }
    return null;
  }

  // Descrizione: dato un 'documentTypeId', questo metodo restituisce l'intero oggetto 'tipo di documento' corrispondente
  public getDocumentTypeObject(documentTypeId: string): string {
    for(const key in this.initialDocuments) {
      if(this.initialDocuments[key].documentTypeId === documentTypeId) {        
        return this.initialDocuments[key];
      }
    }
    return null;
  }

  // Dato un "name" di un oggetto 'part' restituisce la descrizione esterna. Per esempio, dato 'F' Restituirà 'Fronte'
  public fromNameToExternalDescription(part_name) {

    return this.allPartsObtained[part_name];

  }

  // Data una "external description" di un oggetto 'part' restituisce il nome. Per esempio, data 'Fronte' restituirà 'F'
  public fromExternalDescriptionToName(external_description) {

    for(let key in this.allPartsObtained) {
      if(this.allPartsObtained[key].toUpperCase() === external_description.toUpperCase()) {
        return key;
      }
    }

    return null;

  }


  




}
