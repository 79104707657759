import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedPluginModule} from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ApiModule } from "./api";
import { HttpClientModule } from "@angular/common/http";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { CardsContainerComponent } from './components/cards-container/cards-container.component';
import { PopupComponent } from './components/popup/popup.component';
import { IndexComponent } from './components/index/index.component';
import { HeaderComponent } from './components/header/header.component';
import { TabDocumentComponent } from './components/tab-document/tab-document.component';
import { UploadCardComponent } from './components/upload-card/upload-card.component';


import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MainComponent } from './components/main/main.component';
import { AppRoutingModule } from "./app-routing-module";
import { ResultPageComponent } from './components/result-page/result-page.component';

import { BrowserModule } from '@angular/platform-browser';
import { RedirectAfterSessionIdComponent } from './components/redirect-after-session-id/redirect-after-session-id.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { FinishElabComponent } from './components/finish-elab/finish-elab.component';
//import {MatExpansionModule} from '@angular/material/expansion';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    CardsContainerComponent,
    PopupComponent,
    IndexComponent,
    HeaderComponent,
    TabDocumentComponent,
    UploadCardComponent,
    MainComponent,
    ResultPageComponent,
    RedirectAfterSessionIdComponent,
    QrCodeComponent,
    UnauthorizedComponent,
    FinishElabComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([]),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,

    ApiModule,
    HttpClientModule,

    NgbDropdownModule,

    FormsModule,
    PdfJsViewerModule,
    NgbModule,
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
